import { CustomWrapper } from './CustomWrapper';
import logo from './logo.svg';
import { Cluster, clusterApiUrl } from '@solana/web3.js';

export const wrapperOptionsFromSelect = (wrapperSelect: string): { wrapper: typeof CustomWrapper; logo: string } => {
  let wrapper;
  let useLogo;
  if (wrapperSelect === 'logo') {
    useLogo = logo;
  } else if (wrapperSelect === 'custom') {
    wrapper = CustomWrapper;
  } else if (wrapperSelect === 'customAndLogo') {
    wrapper = CustomWrapper;
    useLogo = logo;
  }
  return { wrapper, logo: useLogo };
};

export const getAdminPortalUrl = (stage: string, walletPublicKey: string): string => {
  const suffix = stage === 'prod' ? '' : `-${stage}`;
  return stage === 'local'
    ? `http://localhost:3003/${walletPublicKey}`
    : `https://gatekeeper${suffix}.civic.finance/${walletPublicKey}`;
};

/**
 * There are 1-billion lamports in one SOL
 */
export const LAMPORTS_PER_SOL = 1000000000;

export type ExtendedCluster = Cluster | 'civicnet' | 'localnet';

console.log(`REACT_APP_MAINNET_URL: ${process.env.REACT_APP_MAINNET_URL}`);
console.log(`REACT_APP_DEVNET_URL: ${process.env.REACT_APP_DEVNET_URL}`);
export const clusterEndpoint = (cluster: ExtendedCluster): string => {
  switch (cluster) {
    case 'civicnet':
      return 'https://d3ab7dlfud2b5u.cloudfront.net';
    case 'localnet':
      return 'http://localhost:8899';
    case 'mainnet-beta':
      return process.env.REACT_APP_MAINNET_URL || 'https://rpc.civic.me';
    case 'devnet':
      return process.env.REACT_APP_DEVNET_URL || clusterApiUrl(cluster);
    default: {
      return clusterApiUrl(cluster);
    }
  }
};
