import { DIDDocument } from 'did-resolver';
import { Chain, SignedProof } from '../../types';
import { solanaKeyMatchesWallet, verifySolanaProof } from './solanaVerificationStrategy';
import { ethKeyMatchesWallet, verifyEthereumProof } from './ethereumVerificationStrategy';
import { casperKeyMatchesWallet, verifyCasperProof } from './casperVerificationStrategy';
import { vmToPubkeyHexOrEthAddress } from '../didVerificationMethods';
import DefaultResolver, { DIDResolver } from '../resolver';

/**
 * Different types of signatures are verified differently.
 * They are each implemented as a function looking like this.
 * Whether the second argument is a key or just an address differs by chain.
 * For e.g., Ethereum uses an address to compare against an ecrecover result, whereas Solana and Casper check keys directly.
 */
export type ProofVerificationStrategy = (
  signedProof: SignedProof,
  keyOrAddressToCheck: string,
  message: string
) => Promise<boolean>;

/**
 * Get a list of keys linked to a DID according to the DID document.
 * We currently expect the keys to be either secp256k1 or Ed25519, although we accept various encodings.
 */
export const getKeysFromDid = (didDocument: DIDDocument): string[] => {
  const authIds = (didDocument.authentication || []) as string[];
  const capabilityInvocationIds = didDocument.capabilityInvocation as string[];
  return didDocument.verificationMethod
    .filter((vm) => authIds.concat(capabilityInvocationIds).includes(vm.id))
    .map(vmToPubkeyHexOrEthAddress)
    .filter((key) => !!key);
};
/**
 * Returns a function that should be called to verify the given proof, based on proof metadata.
 * @param proof
 * @returns A function of type ProofVerificationStrategy
 */
export const getProofVerificationStrategy = (proof: SignedProof): ProofVerificationStrategy => {
  // This logic will be updated once we have the signature type passed in from the caller.
  // For now, infer it from the chain type.
  switch (proof.chain) {
    case Chain.SOLANA:
      return verifySolanaProof;
    case Chain.ETHEREUM:
      return verifyEthereumProof;
    case Chain.CASPER:
      return verifyCasperProof;
    default:
      throw new Error(`Unsupported chain type ${Chain[proof.chain]} while verifying proof`);
  }
};

/**
 * Determines whether a given wallet address 'owns' a given DID.
 * This is true if the DID document has either a 'capabilityInvocation' or 'authentication' entry
 * pointing to a VerificationMethod matching the wallet's key.
 * @param walletAddress
 * @param did
 */
export const walletOwnsDID = async (
  walletAddress: string,
  did: string,
  didResolver?: DIDResolver
): Promise<boolean> => {
  // did.civic.com
  const resolver = didResolver ?? new DefaultResolver();
  const didDocument = await resolver.resolve(did);
  const didKeys = getKeysFromDid(didDocument);
  return didKeys.some((key) => keyMatchesWallet(key, walletAddress));
};

const keyMatchesWallet = (keyHex: string, walletAddress: string): boolean =>
  solanaKeyMatchesWallet(keyHex, walletAddress) ||
  ethKeyMatchesWallet(keyHex, walletAddress) ||
  casperKeyMatchesWallet(keyHex, walletAddress);
