import { getToken } from './gatewayUtils';
export const onGatewayTokenChange = (provider, owner, evmGatekeeperNetwork, callback
// eslint-disable-next-line max-params
) => {
    let block = 0;
    return setInterval(() => {
        const pollChain = async () => {
            const latestBlockNumber = await provider.getBlockNumber();
            if (block !== latestBlockNumber) {
                block = latestBlockNumber;
                const token = await getToken(provider, evmGatekeeperNetwork, owner);
                if (token) {
                    callback(token);
                }
            }
        };
        pollChain();
    }, 1000);
};
export const removeGatewayTokenChangeListener = (listenerId) => {
    return clearInterval(listenerId);
};
