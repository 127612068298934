import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable consistent-return */
import { useEffect, useMemo, useState } from 'react';
import { GatewayProvider, prefixLogger, getGatekeeperNetworkData } from '@civic/common-gateway-react';
import * as R from 'ramda';
import { chainImplementation } from './chainImplementation';
import { ownerSignsEnabled } from './config';
const logDebug = prefixLogger('EthereumGatewayProvider').debug;
// eslint-disable-next-line import/prefer-default-export
export const EthereumGatewayProvider = ({ children = null, wallet, gatekeeperNetwork, wrapper, logo, stage = 'prod', redirectUrl, broadcastTransaction, gatekeeperSendsTransaction, handleTransaction, options, }) => {
    const [address, setAddress] = useState('');
    const [network, setNetwork] = useState();
    const [slotId, setSlotId] = useState(undefined);
    const [chainImpl, setChainImpl] = useState();
    useEffect(() => {
        var _a;
        const abortController = new AbortController();
        logDebug('address or provider has changed', { address: wallet === null || wallet === void 0 ? void 0 : wallet.address, provider: (_a = wallet === null || wallet === void 0 ? void 0 : wallet.signer) === null || _a === void 0 ? void 0 : _a.provider });
        if (!wallet) {
            logDebug('useEffect: no wallet, returning', { wallet });
            return;
        }
        const getPublicKeyAndNetwork = async () => {
            var _a;
            if (abortController.signal.aborted) {
                return;
            }
            if (!wallet.signer || !wallet.address) {
                return;
            }
            (_a = wallet.signer.provider) === null || _a === void 0 ? void 0 : _a.getNetwork().then((walletNetwork) => {
                if (abortController.signal.aborted) {
                    return;
                }
                logDebug('setting network', { network: walletNetwork });
                setNetwork(walletNetwork);
            });
            logDebug('setting wallet address', wallet.address);
            setAddress(wallet.address);
        };
        logDebug('getting public key and network...');
        getPublicKeyAndNetwork();
        return () => abortController.abort();
    }, [wallet]);
    const walletAdapter = useMemo(() => {
        if (!address) {
            return undefined;
        }
        return {
            publicKey: address,
        };
    }, [address]);
    useEffect(() => {
        getGatekeeperNetworkData(stage, gatekeeperNetwork).then(gknData => { var _a, _b; return setSlotId((_b = (_a = gknData === null || gknData === void 0 ? void 0 : gknData.chains) === null || _a === void 0 ? void 0 : _a.ethereum) === null || _b === void 0 ? void 0 : _b.chainSpecificId); });
    }, [gatekeeperNetwork, stage]);
    /**
     * Handle the wallet changing, clean up existing listeners
     */
    useEffect(() => {
        if (chainImpl) {
            const chainImplInitProps = {
                network: (network === null || network === void 0 ? void 0 : network.chainId) ? network.chainId.toString() : network === null || network === void 0 ? void 0 : network.name,
                owner: address,
                gatekeeperNetwork,
                stage,
            };
            if (!R.equals(chainImpl.initProps, chainImplInitProps)) {
                logDebug(`useEffect existing chain implementation props are different, calling chainImpl.onDestroy`, {
                    existingInitProps: chainImpl.initProps,
                    newInitProps: chainImplInitProps,
                });
                chainImpl.onDestroy();
            }
        }
    }, [chainImpl === null || chainImpl === void 0 ? void 0 : chainImpl.initProps, address, network, address, gatekeeperNetwork, stage]);
    useEffect(() => {
        logDebug('Inputs changed, recreating chainImpl', { address, gatekeeperNetwork, network });
        if (wallet && address && gatekeeperNetwork && network && slotId) {
            return setChainImpl(chainImplementation({
                wallet,
                network,
                slotId,
                owner: address,
                gatekeeperNetwork,
                stage,
                gatekeeperSendsTransaction: !ownerSignsEnabled({ gatekeeperSendsTransaction, broadcastTransaction }),
                handleTransaction,
            }));
        }
        logDebug('Undefined inputs, return undefined for chainImplementation', { address, gatekeeperNetwork, network });
        setChainImpl(undefined);
    }, [address, network, gatekeeperNetwork, gatekeeperSendsTransaction, broadcastTransaction, stage, handleTransaction, slotId]);
    return (_jsx(GatewayProvider
    // TODO: Update props to only take in the address
    , Object.assign({ 
        // TODO: Update props to only take in the address
        wallet: walletAdapter, stage: stage, chainImplementation: chainImpl, 
        // No default for gatekeeperNetworkAddress, in this case we use Civic's dev network.
        gatekeeperNetwork: gatekeeperNetwork, wrapper: wrapper, logo: logo, redirectUrl: redirectUrl, 
        // TODO remove broadcastTransaction in future version
        ownerSigns: ownerSignsEnabled({ gatekeeperSendsTransaction, broadcastTransaction }), options: options }, { children: children })));
};
export { useEthereumGateway } from './chainImplementation';
