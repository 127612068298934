const ethereumVersionedEndpoint = 'v1/token/ethereum';
export const GATEKEEPER_ENDPOINTS = {
    local: `http://localhost:3001/local/${ethereumVersionedEndpoint}`,
    test: `https://dev-gatekeeper-api.civic.com/${ethereumVersionedEndpoint}`,
    dev: `https://dev-gatekeeper-api.civic.com/${ethereumVersionedEndpoint}`,
    preprod: `https://preprod-gatekeeper-api.civic.com/${ethereumVersionedEndpoint}`,
    prod: `https://gatekeeper-api.civic.com/${ethereumVersionedEndpoint}`,
};
export const getGatekeeperEndpoint = (stage) => {
    const endpoint = GATEKEEPER_ENDPOINTS[stage];
    if (!endpoint) {
        throw new Error(`Invalid stage ${stage}`);
    }
    return endpoint;
};
/**
 * Return the value of owner signs enabled based on the deprecated broadcastTransaction flag as well as gatekeeperSendsTransaction.
 * If gatekeeperSendsTransaction is defined, then ownerSings is the inverse of its value
 * If gatekeeperSendsTransaction is not defined, then ownerSings is the inverse of broadcastTransaction
 * If neither are defined, ownerSigns is enabled
 */
export const ownerSignsEnabled = ({ gatekeeperSendsTransaction, broadcastTransaction, }) => {
    const gatekeeperSendsTransactionSpecified = gatekeeperSendsTransaction !== undefined;
    return gatekeeperSendsTransactionSpecified ? !gatekeeperSendsTransaction : !(broadcastTransaction || false);
};
const isLocal = (stage) => stage === 'local';
const domain = (stage) => {
    if (isLocal(stage))
        return `localhost:3001`;
    return `${stage === 'prod' ? '' : 'dev.'}api.civic.com`;
};
const basePathSuffix = (stage) => (stage === 'prod' ? '' : `-${stage}`);
const domainBasePath = (stage) => {
    return isLocal(stage) ? 'local' : `ociv-gatekeeper${basePathSuffix(stage)}`;
};
export const getVerifierAddress = (stage) => `${domain(stage)}/${domainBasePath(stage)}`;
