export const TESTID_DISCONNECT_WALLET = 'DISCONNECT_WALLET';
export const TESTID_SHOW_SECRET_KEY = 'SHOW_SECRET_KEY';
export const TESTID_CONNECT_WALLET = 'CONNECT_WALLET';
export const TESTID_GATEWAY_STATUS = 'GATEWAY_STATUS';
export const TESTID_REQUEST_TOKEN = 'REQUEST_TOKEN';
export const TESTID_RECONNECT_WALLET = 'RECONNECT_WALLET';
export const TESTID_CURRENT_IFRAME_URL = 'CURRENT_IFRAME_URL';
export const TESTID_ADAPETER_CONNECT_WALLET = 'ADAPETER_CONNECT_WALLET';
export const TESTID_CURRENT_GATEKEEPER_NETWORK = 'CURRENT_GATEKEEPER_NETWORK';
export const TESTID_GATEWAY_TOKEN = 'GATEWAY_TOKEN';
export const TESTID_POWO_WALLET = 'POWO_WALLET';
export const TESTID_AIRDROP = 'AIRDROP';
export const TESTID_POWO_PROOF = 'POWO_PROOF';
export const TESTID_CIVIC_SIGN_PROOF = 'CIVIC_SIGN_PROOF';
export const TESTID_CIVIC_SIGN_NONCE = 'CIVIC_SIGN_NONCE';
export const TESTID_CIVIC_SIGN_DID = 'CIVIC_SIGN_DID';
export const TESTID_WALLET_ADDRESS = 'WALLET_ADDRESS';
export const TESTID_SECRET_KEY = 'SECRET_KEY';
export const TESTID_GATEKEEPER_NETWORK = 'GATEKEEPER_NETWORK';
export const TESTID_CHAIN = 'CHAIN';
export const TESTID_CHAIN_NETWORK = 'CHAIN_NETWORK';
export const TESTID_CLIENT_SENDS_CHECKBOX = 'CLIENT_SENDS_CHECKBOX';
export const TESTID_CLIENT_SENDS_FAKE_CHECKBOX = 'CLIENT_SENDS_FAKE_CHECKBOX';
export const TESTID_CLIENT_SENDS_NO_DELAY = 'CLIENT_SENDS_NO_DELAY';
