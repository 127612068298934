/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
// eslint-disable @typescript-eslint/no-empty-function
import React, { useCallback, useContext, useEffect, useState } from 'react';
import logger from '../../logger';
import { TransactionRequest, Wallet, TypedDataDomain as V6TypedDataDomain, ethers, Signer } from 'ethers';
import { TypedDataDomain, TypedDataField } from '@ethersproject/abstract-signer';
import { create } from '@identity.com/prove-ethereum-wallet';
import { getGatekeeperEndpointNoSuffix } from '../../config';
import { useChain } from '@civic/multichain-connect-react-core';
import { EthereumGatewayWallet } from '@civic/ethereum-gateway-react';
import { walletClientToSigner } from './useAdapters';
import { useWalletClient, createConfig, configureChains } from 'wagmi';
import { HDWalletWithConfirmation } from './HDWalletWithConfirmation';
import { chainIdFromNetwork, evmChains } from './useEthereum';
import { publicProvider } from 'wagmi/providers/public';

function selectEthereumNetwork(network: string): ethers.JsonRpcProvider | undefined {
  let provider;

  switch (network) {
    case 'ethereum':
      provider = new ethers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_API_KEY
          ? `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`
          : 'https://ethereum.publicnode.com'
      );
      break;
    case 'sepolia':
      provider = new ethers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_API_KEY
          ? `https://sepolia.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`
          : 'https://ethereum-sepolia.publicnode.com'
      );
      break;
    case 'goerli':
      provider = new ethers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_API_KEY
          ? `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`
          : 'https://ethereum-goerli.publicnode.com'
      );
      break;
    case 'polygon':
      provider = new ethers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_API_KEY
          ? `https://polygon-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`
          : 'https://polygon-bor.publicnode.com'
      );
      break;
    case 'polygon mumbai':
      provider = new ethers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_API_KEY
          ? `https://polygon-mumbai.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`
          : 'https://polygon-mumbai.blockpi.network/v1/rpc/public' // https://chainlist.org/chain/80001
      );
      break;
    case 'arbitrum':
      provider = new ethers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_API_KEY
          ? `https://arbitrum-mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`
          : 'https://arbitrum-one.publicnode.com'
      );
      break;
    case 'arbitrum goerli':
      provider = new ethers.JsonRpcProvider(
        process.env.REACT_APP_INFURA_API_KEY
          ? `https://arbitrum-goerli.infura.io/v3/${process.env.REACT_APP_INFURA_API_KEY}`
          : 'https://goerli-rollup.arbitrum.io/rpc'
      );
      break;
    default:
      console.log(`Unsupported network.  Received ${network}`);
      break;
  }
  return provider;
}

export const signMessage = async (wallet: Signer, stage: string): Promise<string | undefined> => {
  const verifierAddress = getGatekeeperEndpointNoSuffix(stage).split('://')[1]; // don't include the protocol part of the URL
  console.log('verifierAddress', verifierAddress);
  const proof = await create(
    (domain: TypedDataDomain, types: Record<string, TypedDataField[]>, value) =>
      wallet.signTypedData(domain as V6TypedDataDomain, types, value),
    {
      verifierAddress,
    }
  );
  return proof;
};

type WalletContextProps = {
  wallet: EthereumGatewayWallet | undefined;
  generate: () => void;
  disconnect: () => void;
  walletFromSecret: (secretKey: string) => Wallet;
  stage: string;
  chainId: number | undefined;
  network: string | undefined;
};

const walletFromKeys = (wallet: Wallet): Wallet =>
  ({
    ...wallet,
    signTransaction: (transaction: TransactionRequest): Promise<string> => {
      logger.debug('!!!signTransaction before confirm');
      const result = confirm(
        'Auto-sign transaction? This is the demo app equivalent of a Sollet zero-amount confirmation dialog'
      );

      if (result) {
        logger.debug('!!!signTransaction after confirm');
        return Promise.resolve(wallet.signTransaction(transaction));
      }

      return Promise.reject(new Error('Failed to sign the transaction'));
    },
  } as Wallet);

export const walletFromSecret = (secretKey: string): Wallet => {
  const wallet = new Wallet(secretKey);
  return walletFromKeys(wallet);
};

export const makeNewWallet = (network: string): Wallet => {
  console.log('NETWORK from makeNewWallet is ' + network);
  const provider = selectEthereumNetwork(network);
  const newWallet = Wallet.createRandom();
  return new Wallet(newWallet.privateKey, provider);
};

const WalletContext = React.createContext<WalletContextProps>({
  wallet: undefined,
  generate: () => {},
  disconnect: () => {},
  walletFromSecret,
  stage: '',
  chainId: undefined,
  network: undefined,
});

type Props = {
  children: JSX.Element | null;
  stage: string;
  network: string;
};

export const EthereumWalletProvider: React.FC<Props> = ({ children = null, network, stage }) => {
  const { data: walletClient } = useWalletClient();
  const [wallet, setWallet] = useState<EthereumGatewayWallet | undefined>();
  const { selectedChain } = useChain();
  const generate = useCallback(() => {
    const generatedWallet = makeNewWallet(network);
    const newWallet = new HDWalletWithConfirmation(generatedWallet);
    console.log('generate', newWallet);
    return setWallet({
      address: generatedWallet.address,
      signer: newWallet,
    });
  }, [network, setWallet]);
  const disconnect = useCallback(() => setWallet(undefined), [setWallet]);

  const chainId = chainIdFromNetwork(network);
  useEffect(() => {
    console.log('useEffect walletClient', walletClient);
    // the wallet client chain is set asynchronously, so wait until
    // it's set before setting the wallet
    if (!walletClient?.chain) {
      return setWallet(undefined);
    }
    if (walletClient && walletClient?.account) {
      const signer = walletClientToSigner(walletClient);
      setWallet({ address: walletClient.account?.address, signer });
    }
  }, [walletClient, selectedChain]);

  /**
   * Update wagmi config so that any dependant hooks will use the correct network
   */
  useEffect(() => {
    const chainFromSelectedNetwork = evmChains.find(
      (evmChain) => evmChain.name.toLowerCase() === network.toLowerCase()
    );
    const isGeneratedWallet = wallet?.signer instanceof HDWalletWithConfirmation;
    // for non-rainbow kit wallets, i.e. generated ones, update the current wagmi config to use the selected network
    // so that feeConfig retrieval will still work
    if (chainFromSelectedNetwork && isGeneratedWallet) {
      const { publicClient, webSocketPublicClient } = configureChains([chainFromSelectedNetwork], [publicProvider()]);
      // we don't use the return value from here, we just update wagmi so that hooks wilk work
      createConfig({
        publicClient,
        webSocketPublicClient,
      });
    }
  }, [network, wallet]);
  return (
    <WalletContext.Provider
      value={{
        wallet,
        generate,
        disconnect,
        walletFromSecret,
        stage,
        chainId,
        network,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useEthereumWallet = (): WalletContextProps => useContext(WalletContext);
