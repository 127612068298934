import { create } from '@identity.com/prove-ethereum-wallet';
import { ChainError, ChainType, prefixLogger, SignatureMethod, useGateway, ErrorCode, } from '@civic/common-gateway-react';
import { getGatekeeperEndpoint } from './config';
import { VERSION } from './version';
import { signAndSendTransactionUsingWallet, parseTransactionErrorCode, ethersV6TransactionRequestFromJSONString, } from './utils';
import { onGatewayTokenChange, removeGatewayTokenChangeListener } from './gateway/subscription';
import { getToken } from './gateway/gatewayUtils';
const logDebug = prefixLogger('ETHEREUM chain implementation').debug;
const listeners = [];
// eslint-disable-next-line import/prefer-default-export
export const chainImplementation = ({ wallet, network, owner, gatekeeperNetwork, slotId, stage, gatekeeperSendsTransaction, handleTransaction, }) => {
    logDebug('chainImplementation render');
    // either the chain ID ( if set ) or the network name. GK supports both.
    const serializedChainNetwork = network.chainId > 0 ? network.chainId.toString() : network.name;
    // the gatekeeper network value on the EVM GatewayToken contract (the "slotId" in the EIP3525 interface)
    const gknSlotId = BigInt(slotId);
    const evmGatekeeperNetwork = { slotId: gknSlotId, address: gatekeeperNetwork };
    const getListenerForOwner = (inOwner, tokenDidChange) => {
        var _a;
        if (!((_a = wallet.signer) === null || _a === void 0 ? void 0 : _a.provider)) {
            throw new Error('Provider required to listen for token changes');
        }
        return onGatewayTokenChange(wallet.signer.provider, inOwner, evmGatekeeperNetwork, (token) => {
            // only fire the event if a valid token exists
            if (token) {
                tokenDidChange(token);
            }
        });
    };
    return {
        initProps: {
            network: serializedChainNetwork,
            owner,
            gatekeeperNetwork,
            stage,
        },
        dAppHandlesTransactions: !!handleTransaction,
        addOnGatewayTokenChangeListener: async (gatewayToken, tokenDidChange) => {
            logDebug('addOnGatewayTokenChangeListener...', { gatewayToken });
            const listener = getListenerForOwner(gatewayToken.owner, tokenDidChange);
            return listeners.push({ unsubscribe: () => removeGatewayTokenChangeListener(listener) }) - 1;
        },
        addOnGatewayTokenCreatedOrChangedListener: (tokenDidChange) => {
            logDebug('addOnGatewayTokenCreatedOrChangedListener...', tokenDidChange);
            const listener = getListenerForOwner(owner, tokenDidChange);
            return listeners.push({ unsubscribe: () => removeGatewayTokenChangeListener(listener) }) - 1;
        },
        removeOnGatewayTokenChangeListener: (listenerId) => {
            logDebug('removeOnGatewayTokenChangeListener listenerId', { listenerId, listenersLength: listeners.length });
            if (listenerId >= listeners.length) {
                throw new Error('Invalid listener id');
            }
            // Note - do not remove the listener from the array as that changes the index of others
            listeners[listenerId].unsubscribe();
        },
        findGatewayToken: async () => {
            var _a, _b;
            if (!((_a = wallet.signer) === null || _a === void 0 ? void 0 : _a.provider)) {
                return undefined;
            }
            const onChainToken = await getToken((_b = wallet.signer) === null || _b === void 0 ? void 0 : _b.provider, evmGatekeeperNetwork, owner);
            logDebug('findGatewayToken', onChainToken);
            if (!onChainToken) {
                return undefined;
            }
            return onChainToken;
        },
        proveWalletOwnership: async (message) => {
            if (!message) {
                throw new Error('Need to pass a message to prove wallet ownership');
            }
            try {
                const proof = await create((domain, types, value) => {
                    if (!wallet.signer) {
                        throw new Error('Wallet signer not set');
                    }
                    return wallet.signer.signTypedData(domain, types, value);
                }, {
                    message,
                });
                return { proof, signatureMethod: SignatureMethod.MESSAGE };
            }
            catch (error) {
                const parsedError = error;
                logDebug('handleUserSignedTransaction', { error, errorMessage: parsedError.message });
                const useErrorCode = parseTransactionErrorCode(parsedError) === ErrorCode.SIGN_TRANSACTION_ERROR
                    ? ErrorCode.POWO_ERROR
                    : undefined;
                throw new ChainError(parsedError.message, useErrorCode);
            }
        },
        handleUserSignedTransaction: async (partiallySignedTx) => {
            if (gatekeeperSendsTransaction) {
                throw Error('Should not call handleUserSignedTransaction if gatekeeperSendsTransaction');
            }
            const partiallySignedTxObject = ethersV6TransactionRequestFromJSONString(partiallySignedTx);
            if (handleTransaction) {
                logDebug('custom handleTransaction function present, calling now...');
                const handleTransactionResult = await handleTransaction(partiallySignedTxObject).catch((error) => {
                    throw new ChainError(error.message, ErrorCode.CUSTOM_HANDLE_TRANSACTION_ERROR, prefixLogger('ETHEREUM chain implementation'));
                });
                return handleTransactionResult.hash;
            }
            try {
                const result = await signAndSendTransactionUsingWallet(wallet)(partiallySignedTxObject);
                return result.hash;
            }
            catch (error) {
                const parsedError = error;
                logDebug('handleUserSignedTransaction', { error, errorMessage: parsedError.message });
                throw new ChainError(parsedError.message, parseTransactionErrorCode(error));
            }
        },
        chainType: ChainType.ETHEREUM,
        chainNetwork: serializedChainNetwork,
        httpConfig: {
            baseUrl: getGatekeeperEndpoint(stage),
            queryParams: { network: serializedChainNetwork },
            headers: { 'x-civic-client': VERSION },
        },
        onDestroy: () => {
            listeners.forEach((listener) => listener !== undefined && listener.unsubscribe());
        },
        did: `did:pkh:eip155:1:${owner}`,
    };
};
export const useEthereumGateway = () => {
    const { gatewayToken } = useGateway();
    const ethereumGatewayToken = gatewayToken
        ? {
            gatekeeperNetworkAddress: gatewayToken.gatekeeperNetworkAddress,
            owner: gatewayToken.owner,
            state: gatewayToken.state,
            identifier: gatewayToken.identifier,
            expiryTime: gatewayToken.expiryTime,
        }
        : undefined;
    return Object.assign(Object.assign({}, useGateway()), { gatewayToken: ethereumGatewayToken });
};
