import { networks as supportedNetworks } from '@civic/civic-eth-provider';
import { SymbolInfo, SupportedSymbol } from './types';
import { icons } from './icons';

const getIcons = (): Record<SupportedSymbol, SymbolInfo> => {
  const evmNetworks = Object.values(supportedNetworks).reduce((acc, n) => {
    const symbol = n.symbol as SupportedSymbol;
    if (symbol) {
      acc[symbol] = {
        chain: 'Ethereum',
        symbol: n.symbol,
        icon: icons[symbol],
      };
    }
    return acc;
  }, {} as Record<SupportedSymbol, SymbolInfo>);

  return {
    ...evmNetworks,
    SOL: {
      chain: 'Solana',
      symbol: 'SOL',
      icon: icons.SOL,
    },
  };
};

export * from './types';

export const getIconInfo = (symbol: SupportedSymbol): SymbolInfo => {
  return getIcons()[symbol];
};
export { icons } from './icons';
