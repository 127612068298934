import CSS from 'csstype';
import React from 'react';

const customWrapperStyle: CSS.Properties = {
  backgroundColor: 'rgba(0,0,0,1)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 999,
  width: '100%',
  height: '100%',
  overflow: 'auto',
  paddingTop: '5%',
};

const customWrapperContentStyle: CSS.Properties = {
  backgroundColor: '#fefefe',
  margin: 'auto',
  width: '90%',
  position: 'relative',
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const CustomWrapper: React.FC = ({ children = null }) => {
  return (
    <div style={customWrapperStyle}>
      <div style={customWrapperContentStyle}>{children}</div>
    </div>
  );
};
