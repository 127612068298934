import { ErrorCode, prefixLogger } from '@civic/common-gateway-react';
const logDebug = prefixLogger('ethereum utils').debug;
const logWarn = prefixLogger('ethereum utils').warn;
// https://github.com/MetaMask/rpc-errors/blob/main/src/error-constants.ts
const insufficientFundErrorCodes = ['-32005', '-32000'];
const userRejectedErrorCodes = ['ACTION_REJECTED'];
const isErrorGasRequiredExceedallowance = (error) => {
    var _a, _b;
    if (!error) {
        return false;
    }
    return !!(((error === null || error === void 0 ? void 0 : error.code) && insufficientFundErrorCodes.includes(`${error === null || error === void 0 ? void 0 : error.code}`)) ||
        ((_a = error.message) === null || _a === void 0 ? void 0 : _a.includes('gas required exceeds allowance')) ||
        ((_b = error.errorMessage) === null || _b === void 0 ? void 0 : _b.includes('gas required exceeds allowance')));
};
const isClientWalletFundsError = (error) => {
    logDebug('isClientWalletFundsError, error:', error);
    logDebug('isClientWalletFundsError, isErrorGasRequiredExceedallowance(error):', isErrorGasRequiredExceedallowance(error));
    logDebug('isClientWalletFundsError, isErrorGasRequiredExceedallowance(error?.error):', isErrorGasRequiredExceedallowance(error === null || error === void 0 ? void 0 : error.error));
    if (isErrorGasRequiredExceedallowance(error) || isErrorGasRequiredExceedallowance(error === null || error === void 0 ? void 0 : error.error)) {
        return true;
    }
    return false;
};
const isUserRejectedError = (error) => {
    var _a, _b, _c;
    return !!(((error === null || error === void 0 ? void 0 : error.code) && userRejectedErrorCodes.includes(`${error === null || error === void 0 ? void 0 : error.code}`)) ||
        ((_a = error.message) === null || _a === void 0 ? void 0 : _a.includes('user rejected transaction')) ||
        ((_b = error.reason) === null || _b === void 0 ? void 0 : _b.includes('user rejected transaction')) ||
        ((_c = error.errorMessage) === null || _c === void 0 ? void 0 : _c.includes('user rejected transaction')));
};
export const parseTransactionErrorCode = (error) => {
    if (isClientWalletFundsError(error)) {
        logWarn('parseTransactionErrorCode - INSUFFICIENT_FUNDS error detected', error);
        return ErrorCode.INSUFFICIENT_FUNDS;
    }
    if (isUserRejectedError(error)) {
        logWarn('parseTransactionErrorCode - SIGN_TRANSACTION_ERROR error detected', error);
        return ErrorCode.SIGN_TRANSACTION_ERROR;
    }
    return undefined;
};
// Wallets do not calculate the gas limit correctly for forwarded txes using the FlexibleNonceForwarder
// This most likely has to do with the fact that the gas will vary depending on the number of "concurrent" txes
// This forces the wallet to use a gas limit that is sufficient
const RECOMMENDED_GAS_LIMIT = 400000;
export const signAndSendTransactionUsingWallet = (wallet) => async (txRequest) => {
    if (!wallet.signer || !wallet.address) {
        throw new Error('Need a connected wallet');
    }
    const txRequestWithDefaults = Object.assign(Object.assign({}, txRequest), { from: wallet.address });
    const populatedTx = await wallet.signer.populateTransaction(txRequestWithDefaults);
    // override the gas price so that the Market rather than 'Site suggested' fees
    // are used, required because Ethers/the GK API aren't providing good fee suggestions for some chains,
    // and it's better to let the Wallet handle them
    populatedTx.maxFeePerGas = undefined;
    populatedTx.maxPriorityFeePerGas = undefined;
    if (Object.hasOwnProperty.call(populatedTx, 'gasPrice')) {
        populatedTx.gasPrice = undefined;
    }
    // the gatekeeper-api should set the gas limit, however, set to RECOMMENDED_GAS_LIMIT if it's not set
    if (!populatedTx.gasLimit && RECOMMENDED_GAS_LIMIT) {
        populatedTx.gasLimit = BigInt(RECOMMENDED_GAS_LIMIT);
    }
    // forwarded transactions are not being estimated with a gas limit high enough
    return wallet.signer.sendTransaction(populatedTx);
};
export const ethersV6TransactionRequestFromJSONString = (partiallySignedTx) => {
    const parsedObj = JSON.parse(partiallySignedTx);
    const txRequest = Object.assign(Object.assign(Object.assign({}, parsedObj), (parsedObj.value ? { value: BigInt(parsedObj.value.hex) } : {})), (parsedObj.gasLimit ? { gasLimit: BigInt(parsedObj.gasLimit.hex) } : {}));
    return txRequest;
};
