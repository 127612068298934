import React, { ReactNode } from 'react';
import './index.css';
import App from './App';
import '@solana/wallet-adapter-react-ui/styles.css';
import { createRoot } from 'react-dom/client';
import { GatekeeperNetworkServiceProvider } from './GatekeeperNetworkServiceContext';

const container = document.getElementById('root');

const ConditionalStrictMode: React.FC<{ children: ReactNode }> = ({ children }) => {
  // Disable strict mode if stage === e2e to prevent double - rendering of components
  const disableStrictMode = process.env.REACT_APP_DISABLE_STRICT_MODE === 'true';
  return disableStrictMode ? <>{children}</> : <React.StrictMode>{children}</React.StrictMode>;
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(
  <ConditionalStrictMode>
    <GatekeeperNetworkServiceProvider>
      <App />
    </GatekeeperNetworkServiceProvider>
  </ConditionalStrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
