import {
  Chain,
  SignatureMethod,
  SolanaSignedProofOptions,
  SolanaWalletAdapter,
  Wallet,
  WalletSignedProof,
} from '../types';
import { DEFAULT_CONFIG, create, proveTransaction } from '@identity.com/prove-solana-wallet';
import { util } from '@identity.com/cryptid';
import { RemoteWallet } from './remote';
import { windowEventListener, EventListener } from './eventListener';

export const defaultOptions = {
  ...DEFAULT_CONFIG,
  recentBlockCheck: false,
};

const TIMEOUT = 60 * 1000;

const signTransaction = async (
  walletAdapter: SolanaWalletAdapter,
  options?: SolanaSignedProofOptions
): Promise<WalletSignedProof> => {
  const result = await proveTransaction(
    walletAdapter.publicKey,
    walletAdapter.signTransaction.bind(walletAdapter),
    Object.assign(defaultOptions, options)
  );
  const proof = result.toString('base64');
  return {
    proof,
    chain: Chain.SOLANA,
    signatureMethod: SignatureMethod.TRANSACTION,
  };
};

const signMessage = async (walletAdapter: SolanaWalletAdapter, message: string): Promise<WalletSignedProof> => {
  const proof = await create((messageToSign) => walletAdapter.signMessage(Buffer.from(messageToSign)), message);
  return {
    proof,
    chain: Chain.SOLANA,
    signatureMethod: SignatureMethod.MESSAGE,
  };
};

export const LocalSolanaWallet = (walletAdapter: SolanaWalletAdapter, options?: SolanaSignedProofOptions): Wallet => ({
  getPublicKey: () => Promise.resolve(walletAdapter.publicKey.toBase58()),
  getDid: () => Promise.resolve(util.publicKeyToDid(walletAdapter.publicKey)),
  signProof: async (message: string) => {
    return walletAdapter.signMessage ? signMessage(walletAdapter, message) : signTransaction(walletAdapter, options);
  },
  signMessage: async (message: Uint8Array) => {
    const signature = await walletAdapter.signMessage(message);
    return signature;
  },
  chain: Chain.SOLANA,
});

/**
 *
 * @param timeout timeout in milliseconds waiting for the remote wallet to receive a response
 * @param eventListener the event listener to use for the remote wallet
 * @returns {Wallet}
 */
export const RemoteSolanaWallet = ({
  timeout = TIMEOUT,
  eventListener = windowEventListener(),
  instanceId,
  debug,
}: {
  timeout: number;
  eventListener?: EventListener;
  instanceId: string;
  debug?: boolean;
}): Wallet => {
  const remoteWallet = RemoteWallet({
    chain: Chain.SOLANA,
    eventListener,
    timeout,
    instanceId,
    debug,
  });

  return remoteWallet;
};
