import { AccountInfo, CivicSignProve, SignedProof, Wallet } from '../types';
import { getLibraryVersion } from './utils';

export const CivicProveImplementation = (wallet?: Wallet): CivicSignProve => {
  const requestDid = async (): Promise<AccountInfo> => {
    const publicKey = await wallet.getPublicKey();
    const did = await wallet.getDid();
    return {
      did,
      publicKey,
    };
  };

  const requestProof = async (message: string): Promise<SignedProof> => {
    const signedWalletProof = await wallet.signProof(message);
    return {
      ...signedWalletProof,
      libraryVersion: getLibraryVersion(),
    };
  };

  const signMessage = async (message: Uint8Array): Promise<Uint8Array> => {
    return await wallet.signMessage(message);
  };

  return {
    requestDid,
    requestProof,
    signMessage,
  };
};
