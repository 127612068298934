import {
  BlockTag,
  Provider,
  Signer,
  TransactionLike,
  TransactionRequest,
  TransactionResponse,
  TypedDataDomain,
  TypedDataField,
  Wallet,
} from 'ethers';
import { confirmAlert } from 'react-confirm-alert';
import logger from '../../logger';

export class HDWalletWithConfirmation implements Signer {
  provider: Provider | null;
  constructor(readonly inWallet: Wallet) {
    this.provider = this.inWallet.provider;
  }

  connect(provider: Provider | null): Signer {
    return this.inWallet.connect(provider);
  }
  getAddress(): Promise<string> {
    return this.inWallet.getAddress();
  }
  getNonce(blockTag?: BlockTag | undefined): Promise<number> {
    return this.inWallet.getNonce(blockTag);
  }
  populateCall(tx: TransactionRequest): Promise<TransactionLike<string>> {
    return this.inWallet.populateCall(tx);
  }
  populateTransaction(tx: TransactionRequest): Promise<TransactionLike<string>> {
    return this.inWallet.populateTransaction(tx);
  }
  estimateGas(tx: TransactionRequest): Promise<bigint> {
    return this.inWallet.estimateGas(tx);
  }
  call(tx: TransactionRequest): Promise<string> {
    return this.inWallet.call(tx);
  }
  resolveName(name: string): Promise<string | null> {
    return this.inWallet.resolveName(name);
  }
  signTransaction(tx: TransactionRequest): Promise<string> {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: 'Confirm signTransaction',
        message: 'Sign transaction? This is the demo app equivalent of a connected wallet confirmation dialog',
        buttons: [
          {
            label: 'Confirm',
            onClick: async () => {
              logger.debug('!!!eth signTransaction after confirm');
              return resolve(this.inWallet.signTransaction(tx));
            },
          },
          {
            label: 'Cancel',
            onClick: () => reject('User refused to confirm transaction'),
          },
        ],
      });
    });
  }
  sendTransaction(tx: TransactionRequest): Promise<TransactionResponse> {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: 'Confirm sendTransaction',
        message: 'Send transaction? This is the demo app equivalent of a connected wallet confirmation dialog',
        buttons: [
          {
            label: 'Confirm',
            onClick: async () => {
              logger.debug('!!!eth sendTransaction after confirm');
              return resolve(this.inWallet.sendTransaction(tx));
            },
          },
          {
            label: 'Cancel',
            onClick: () => reject('User refused to confirm transaction'),
          },
        ],
      });
    });
  }
  signMessage(message: string | Uint8Array): Promise<string> {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: 'Confirm signMessage',
        message: `Sign message '${message}'? This is the demo app equivalent of a connected wallet confirmation dialog`,
        buttons: [
          {
            label: 'Confirm',
            onClick: async () => {
              logger.debug('!!!eth signMessage after confirm');
              return resolve(this.inWallet.signMessage(message));
            },
          },
          {
            label: 'Cancel',
            onClick: () => reject('User refused to confirm transaction'),
          },
        ],
      });
    });
  }
  signTypedData(
    domain: TypedDataDomain,
    types: Record<string, TypedDataField[]>,
    value: Record<string, unknown>
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: 'Confirm signTypedData',
        message: `Sign typed data '${JSON.stringify(
          value
        )}'? This is the demo app equivalent of a connected wallet confirmation dialog`,
        buttons: [
          {
            label: 'Confirm',
            onClick: async () => {
              logger.debug('!!!eth signTypedData after confirm');
              return resolve(this.inWallet.signTypedData(domain, types, value));
            },
          },
          {
            label: 'Cancel',
            onClick: () => reject('User refused to confirm transaction'),
          },
        ],
      });
    });
  }
}
