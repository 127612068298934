/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { LoggingInterface } from './provider.logger';

class LogLocal implements LoggingInterface {
  debug(message: string, object?: unknown): void {
    if (object) {
      console.log('debug:', message, object);
    } else {
      console.log('debug:', message);
    }
  }

  info(message: string): void {
    console.log('info:', message);
  }

  warn(message: string): void {
    console.log('warn:', message);
  }

  error(message: string, exception?: unknown): void {
    console.log('error:', message, exception);
  }
}

export default LogLocal;
