import * as bs58 from 'bs58';
import { isAddress as isEthAddress } from '@ethersproject/address';
import { VerificationMethod } from 'did-resolver';
import { jwkToPublicKeyHex } from './jwk';
import logger from '../logger';

/**
 * Returns the hex-encoded public key represented by a VerificationMethod,
 * or an Ethereum address if that's all we have.
 *
 * Ethereum addresses can come from either:
 *  - The ethereumAddress field (https://w3c.github.io/did-spec-registries/#ethereumaddress)
 *  - The blockchainAccountId for VM's of type EcdsaSecp256k1RecoveryMethod2020 (https://w3c.github.io/did-spec-registries/#blockchainaccountid)
 *
 * If we only have an Eth address, the caller will:
 *    - Check the address against an ecrecover for proof verification, or
 *    - Compare the address against the walletAddress param in owns(walletAddress, did)
 *
 * Supports the following VM fields:
 * - publicKeyJwk => converted to hex using the @trust/keyto lib, with our custom logic as fallback.
 * - publicKeyHex => returned as-is
 * - publicKeyBase58 & publicKeyBase64 => re-encoded to hex
 * - ethereumAddress => returned as-is, see above
 *
 * Note that publicKeyMultibase is not currently supported.
 *
 * @param vm A VerificationMethod from a DID document
 * @returns Public Key in hex format, or Eth address .
 *          Returns undefined if the VM type is not supported or something went wrong during encoding,
 *          so other VM's can still be checked.
 */
export const vmToPubkeyHexOrEthAddress = (vm: VerificationMethod): string | undefined => {
  try {
    if (vm.publicKeyJwk) {
      // Uses the @trust/keyto lib to parse JWK, with fallback to our own logic for crv: secp256k1 (currently not supported by the lib)
      return jwkToPublicKeyHex(vm.publicKeyJwk);
    }
    // Even though Multibase is also in the did-core spec, we currently don't support it.

    /*
     * Everything below is deprecated in the did-core spec,
     * but we keep backwards-compatibility with some DID methods / resolvers that use them.
     *
     * We just re-encode to hex, except for the following where it doesn't make sense:
     *   - ethereumAddress : No way to get from an Eth address back to a public key without a signature.
     *       Just return the address as-is
     *      See https://w3c.github.io/did-spec-registries/#ethereumaddress
     *   - blockchainAccountId : This is not always a public key.
     *       See https://w3c.github.io/did-spec-registries/#blockchainaccountid
     */
    if (vm.publicKeyHex) {
      // Already in hex, no recoding needed.
      return vm.publicKeyHex;
    }

    if (vm.publicKeyBase58) {
      return Buffer.from(bs58.decode(vm.publicKeyBase58)).toString('hex');
    }

    if (vm.publicKeyBase64) {
      return Buffer.from(vm.publicKeyBase64, 'base64').toString('hex');
    }

    if (vm.ethereumAddress) {
      // Can't convert an ETH address back to a public key.
      // The caller will use the address as-is in an ecrecover check or directly compare in owns(walletAddress, did)
      return vm.ethereumAddress;
    }

    if (vm.type === 'EcdsaSecp256k1RecoveryMethod2020' && vm.blockchainAccountId) {
      // blockchainAccountId is of a form like eip155:1:0xab16a96d359ec26a11e2c2b3d8f8b8942d5bfcdb
      // Extract just the address from it (everything after the last colon)
      const matches = vm.blockchainAccountId.match(/[^:]+$/);
      if (!matches || matches.length < 1 || !isEthAddress(matches[0])) {
        logger.error(
          'blockchainAccountId does not contain a valid ethereum address for EcdsaSecp256k1RecoveryMethod2020 VM',
          vm
        );
        return undefined;
      }
      return matches[0];
    }

    // We don't support this VM's key representation.
    logger.error('Unsupported VerificationMethod type. Cannot convert to publicKey', vm);
    // Don't throw, allow other VM's to still be checked.
    return undefined;
  } catch (error) {
    logger.error('Could not convert VM to key', { error, vm });
    return undefined;
  }
};
