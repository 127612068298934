import { verify } from '@identity.com/prove-ethereum-wallet';
import { isAddress } from '@ethersproject/address';
import { utils as ethersUtils } from 'ethers';
import { publicKeyConvert } from 'secp256k1';
import { EthereumSignedProofOptions, SignedProof } from '../../types';
import { ProofVerificationStrategy } from '.';
import logger from '../logger';

/**
 * Returns whether the given addressOrKeyToCheck was used to sign the given signedProof.
 *
 * The proof is of the format '<base64 encoded message>.<base64 encoded signature>' .
 * The prove-ethereum-wallet splits and decodes these, and passes them along to the ethers 'verifyTypedData' function,
 * which does an ecrecover to get an Eth address which is then compared to addressToCheck to see if it was a signer of the proof.
 * If addressOrKeyToCheck is a public key, it is converted to an ethereum address to do the ecrecover.
 *
 * @param signedProof The proof to evaluate, of the format '<base64 encoded message>.<base64 encoded signature>'
 * @param addressOrKeyToCheck Either an ethereumAddress or a hex-encoded publicKey.
 * @param expectedMessage The message we expect to appear in the signed proof. This is usually a nonce.
 * @returns Boolean, whether the given key was a signer of the proof.
 */
export const verifyEthereumProof: ProofVerificationStrategy = async (
  signedProof: SignedProof,
  addressOrKeyToCheck: string,
  expectedMessage?: string
): Promise<boolean> => {
  let ethereumAddress: string;

  try {
    ethereumAddress = ethKeyToAddress(addressOrKeyToCheck);
    if (!ethereumAddress || !isAddress(ethereumAddress)) {
      logger.warn('Could not convert key to ethereum address in ethereumVerificationStrategy');
      return false;
    }
    // This performs an ecrecover
    return await verify(ethereumAddress, signedProof.proof, {
      // support proofs sent by older clients that don't provide a message
      ...(expectedMessage
        ? { message: expectedMessage }
        : { verifierAddress: (signedProof.options as EthereumSignedProofOptions).verifierAddress }),
    });
  } catch (err) {
    logger.warn(`Verification failed for addressOrKey ${addressOrKeyToCheck}. Skipping.`, err);
    return false;
  }
};

const ensureKeyDecompressed = (publicKeyHex: string) => {
  let inputKeyBuffer = Buffer.from(publicKeyHex, 'hex');
  if (inputKeyBuffer.length === 64) {
    // This key is not compressed. Just add a 04 prefix so the secp256k1 lib can recognize it.
    inputKeyBuffer = Buffer.concat([Buffer.from([0x04]), inputKeyBuffer]);
  }
  // false => convert to uncompressed form, or return as-is if it's already uncompressed.
  const uncompressedKey = publicKeyConvert(inputKeyBuffer, false);
  return `0x${Buffer.from(uncompressedKey).toString('hex')}`;
};

const ethKeyToAddress = (addressOrKey: string): string => {
  // Make sure any DID prefixes are stripped off.
  addressOrKey = addressOrKey.replace(/(.*):/g, '');

  // If we have an ethereum address already, we're done.
  if (isAddress(addressOrKey)) {
    return addressOrKey;
  }

  // We have a hex-encoded public key, turn it into an address.
  // The incoming key may be compressed, so we have to decompress it as ethers needs uncompressed keys.
  const keyWithout0x = addressOrKey.startsWith('0x') ? addressOrKey.substring(2) : addressOrKey;
  const uncompressedPubKeyHex = ensureKeyDecompressed(keyWithout0x);
  return ethersUtils.computeAddress(uncompressedPubKeyHex);
};

export const ethKeyMatchesWallet = (keyHex: string, walletAddress: string) => {
  try {
    if (!isAddress(walletAddress)) {
      return false;
    }
    return ethKeyToAddress(keyHex).toLowerCase() === walletAddress.toLowerCase();
  } catch {
    return false;
  }
};
