/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { DEFAULT_LOG_LEVEL, LoggingProvider } from './provider.logger';

const consoleWithOptionalObject = (
  method: (message: string, object?: unknown) => void,
  message: string,
  object?: unknown
) => {
  if (object) {
    method(message, object);
  } else {
    method(message);
  }
};

class LogLocal extends LoggingProvider {
  constructor(logLevel: string = DEFAULT_LOG_LEVEL) {
    super(console, logLevel);
  }

  debug(message: string, object?: unknown): void {
    consoleWithOptionalObject(this.logger.debug, message, object);
  }

  info(message: string, object?: unknown): void {
    consoleWithOptionalObject(this.logger.info, message, object);
  }

  warn(message: string, object?: unknown): void {
    consoleWithOptionalObject(this.logger.warn, message, object);
  }

  error(message: string, exception?: unknown): void {
    consoleWithOptionalObject(this.logger.error, message, exception);
  }
}

export default LogLocal;
