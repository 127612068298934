import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import IframeResizer from 'iframe-resizer-react';
import { v4 as uuid } from 'uuid';
import * as R from 'ramda';
import { GatewayStatus, TokenIssuanceState, CivicPassIssuanceStatus, RefreshTokenState, } from '../types';
import logger, { prefixLogger } from '../logger';
import { Wrapper } from '../wrapper/Wrapper';
import { IFRAME_ID, TESTID_IFRAME, TESTID_WRAPPER, TESTID_WRAPPER_CONTAINER } from '../constants';
import GatekeeperClient from '../utils/gatekeeperClient';
import reducer from '../useReducer';
import useUserInteraction from '../useHooks/useUserInteraction';
import useOrchestration from '../useHooks/useOrchestration';
import useWalletHooks from '../useHooks/useWalletHooks';
import { mapStatus, statusDisplayValue } from '../utils/status';
import { usePrevious } from '../useHooks/usePrevious';
import { getFlowId } from '../utils/analytics';
const logDebug = prefixLogger('GatewayContext').debug;
const GatewayContext = React.createContext({
    requestGatewayToken: async () => { },
    gatewayStatus: GatewayStatus.UNKNOWN,
});
// If there is no window object (e.g. in an SSR environment) then the redirectUrl must be passed in.
// If none is passed in, use a default redirect url.
const DEFAULT_REDIRECT_URL = 'https://getpass.civic.com';
const redirectUrlFromWindow = () => (window === null || window === void 0 ? void 0 : window.location.href) ? encodeURIComponent(window.location.href) : DEFAULT_REDIRECT_URL;
export const GatewayProvider = ({ children = null, wallet, chainImplementation, wrapper, logo, stage = 'prod', redirectUrl, gatekeeperNetwork, options = { autoShowModal: true }, ownerSigns, expiryMarginSeconds = 0, partnerAppId, }) => {
    if (options.logLevel && logger.setLogLevel) {
        logger.setLogLevel(options.logLevel);
    }
    const gatekeeperNetworkAddress = gatekeeperNetwork;
    const [state, originalDispatch] = useReducer(reducer, {
        userInitiatedFlow: false,
        options,
        gatewayStatus: GatewayStatus.UNKNOWN,
        tokenRequested: false,
        iframeMinimized: false,
        firstTokenCheck: true,
        renderIframe: false,
        powoFinished: false,
        refreshInProgress: false,
        walletPowoInProgress: false,
        walletAddress: wallet === null || wallet === void 0 ? void 0 : wallet.publicKey,
        iframeSrcUrl: undefined,
        stage,
        redirectUrl: redirectUrl || redirectUrlFromWindow(),
        tokenIssuanceState: TokenIssuanceState.NOT_REQUESTED,
        refreshTokenState: RefreshTokenState.NOT_REQUIRED,
        civicPass: {
            status: CivicPassIssuanceStatus.NOT_REQUESTED,
        },
        gatekeeperNetworkAddress,
        chainType: chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.chainType,
        chainNetwork: chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.chainNetwork,
        ownerSigns,
        ownerTransactionConfirmed: false,
        inputExpiryMarginSeconds: expiryMarginSeconds,
        partnerAppId,
        instanceId: uuid(),
        did: chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.did,
        flowId: getFlowId('', {}),
        fetchOnChainStatus: undefined,
    });
    const dispatch = useCallback((action) => {
        originalDispatch(Object.assign(Object.assign({}, action), { instanceId: state.instanceId }));
    }, [state.instanceId]);
    const { gatewayStatus, iframeMinimized, renderIframe, gatewayToken, iframeSrcUrl, gatewayTokenTransaction, refreshTimeoutId, } = state;
    logDebug('render', { wallet, chainImplementation });
    const { handleWalletDisconnect } = useWalletHooks(chainImplementation, wallet, state, dispatch); // need to handle wallet connect and disconnect first
    useEffect(() => {
        handleWalletDisconnect();
    }, [wallet === null || wallet === void 0 ? void 0 : wallet.publicKey, refreshTimeoutId]);
    // changes to any of these props should reset the state completely
    useEffect(() => {
        dispatch({
            type: 'resetStateWithProps',
            stage,
            walletAddress: wallet === null || wallet === void 0 ? void 0 : wallet.publicKey,
            gatekeeperNetworkAddress,
            chainType: chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.chainType,
            chainNetwork: chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.chainNetwork,
            did: chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.did,
        });
    }, [
        stage,
        wallet === null || wallet === void 0 ? void 0 : wallet.publicKey,
        gatekeeperNetworkAddress,
        chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.chainNetwork,
        chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.chainType,
        chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.did,
    ]);
    // changes to these props shouldn't cause a full state reset
    useEffect(() => {
        dispatch({
            type: 'updateStateWithProps',
            redirectUrl: redirectUrl || redirectUrlFromWindow(),
            ownerSigns,
            inputExpiryMarginSeconds: expiryMarginSeconds,
            partnerAppId,
        });
    }, [redirectUrl, expiryMarginSeconds, partnerAppId, ownerSigns]);
    const { network } = (chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.httpConfig.queryParams) || {};
    const { headers } = (chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.httpConfig) || {};
    const gatekeeperEndpoint = chainImplementation === null || chainImplementation === void 0 ? void 0 : chainImplementation.httpConfig.baseUrl;
    const [gatekeeperClient, setGatekeeperClient] = useState(gatekeeperEndpoint
        ? new GatekeeperClient({
            walletAddress: wallet === null || wallet === void 0 ? void 0 : wallet.publicKey,
            baseUrl: gatekeeperEndpoint,
            stage,
            queryParams: gatekeeperNetworkAddress ? { network, gatekeeperNetworkAddress } : {},
            headers,
        }, state.flowId)
        : undefined);
    // clean up any fetch calls in progress from an old gatekeeper client
    useEffect(() => {
        if (gatekeeperClient &&
            !R.equals(gatekeeperClient.initConfig, {
                walletAddress: wallet === null || wallet === void 0 ? void 0 : wallet.publicKey,
                baseUrl: gatekeeperEndpoint,
                stage,
                queryParams: { network, gatekeeperNetworkAddress },
                headers,
            })) {
            logDebug('useEffect gatekeeperClient initConfig has changed, aborting');
            gatekeeperClient.abort();
        }
        return () => {
            gatekeeperClient === null || gatekeeperClient === void 0 ? void 0 : gatekeeperClient.abort();
        };
    }, [stage, gatekeeperEndpoint, gatekeeperNetworkAddress, network, wallet === null || wallet === void 0 ? void 0 : wallet.publicKey]);
    useEffect(() => {
        const abortController = new AbortController();
        if (!gatekeeperNetworkAddress) {
            logDebug('No gatekeeper network passed in.');
            return;
        }
        // use a timeout to create the gatekeeper client to ensure that the useEffect to abort previous
        // instances doesn't use the new one we want to complete
        setTimeout(() => {
            if (abortController.signal.aborted || !gatekeeperEndpoint) {
                return;
            }
            logDebug('a gatekeeper client parameter changed, creating new instance...', {
                stage,
                gatekeeperEndpoint,
                gatekeeperNetworkAddress,
                network,
                walletAddress: wallet === null || wallet === void 0 ? void 0 : wallet.publicKey,
            });
            setGatekeeperClient(new GatekeeperClient({
                walletAddress: wallet === null || wallet === void 0 ? void 0 : wallet.publicKey,
                baseUrl: gatekeeperEndpoint,
                stage,
                queryParams: { network, gatekeeperNetworkAddress },
                headers,
            }, state.flowId));
        }, 10);
        // eslint-disable-next-line consistent-return
        return () => abortController.abort();
    }, [stage, gatekeeperEndpoint, gatekeeperNetworkAddress, network, wallet === null || wallet === void 0 ? void 0 : wallet.publicKey, state.flowId]);
    // this hook implements the main business logic and handles requesting and refreshing gateway tokens
    useOrchestration({ wallet, chainImplementation, stage, gatekeeperClient }, state, dispatch);
    // requestGatewayToken is the only user-triggered event handled by the component
    // the compliance iframe user interaction is handled using events triggered from the iframe
    const { requestGatewayToken } = useUserInteraction({ wallet }, state, dispatch);
    // /**
    //  * manage local state for display of the close button ui relative to iframe loading
    //  */
    const [iframeLoaded, setIframeLoaded] = useState(false);
    const [iframeLoading, setIframeLoading] = useState(false);
    useEffect(() => {
        logger.info('gatewayStatus', statusDisplayValue(gatewayStatus));
    }, [gatewayStatus]);
    /**
     * Reset the iFrame when removing the iFrame from the DOM
     */
    useEffect(() => {
        if (!renderIframe) {
            setIframeLoaded(false);
            setIframeLoading(false);
        }
    }, [renderIframe]);
    /**
     * iframe loading is set true whenever the src url changes
     */
    const prevSrc = usePrevious(iframeSrcUrl);
    useEffect(() => {
        if (iframeSrcUrl !== prevSrc) {
            setIframeLoading(true);
        }
    }, [iframeSrcUrl]);
    return (_jsx(GatewayContext.Provider, Object.assign({ value: {
            requestGatewayToken,
            gatewayStatus: mapStatus(gatewayStatus),
            gatewayToken: gatewayStatus === GatewayStatus.ACTIVE ? gatewayToken : undefined,
            gatewayTokenTransaction,
            civicPassSrcUrl: state.iframeSrcUrl,
            pendingRequests: state.pending,
        } }, { children: _jsxs(_Fragment, { children: [children, renderIframe && (_jsx("div", Object.assign({ "data-testid": TESTID_WRAPPER_CONTAINER, hidden: iframeMinimized }, { children: _jsx(Wrapper, Object.assign({ "data-testid": TESTID_WRAPPER, onClose: () => dispatch({ type: 'civicPass_close' }), wrapper: wrapper, logo: logo, loaded: iframeLoaded, loading: iframeLoading }, { children: _jsx(IframeResizer, { "data-testid": TESTID_IFRAME, src: iframeSrcUrl, id: `${IFRAME_ID}_${state.instanceId}`, style: {
                                width: '1px',
                                minWidth: '100%',
                                border: 'none',
                                height: '26px',
                                transition: 'height 0.25s ease',
                                pointerEvents: iframeLoaded ? 'auto' : 'none', // disables user input during loading
                            }, heightCalculationMethod: "min", checkOrigin: false, onLoad: () => setIframeLoaded(true), inPageLinks: true, allow: "camera", allowFullScreen: true, frameBorder: "0" }) })) })))] }) })));
};
export const useGateway = () => useContext(GatewayContext);
