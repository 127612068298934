import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import { GatewayProvider, prefixLogger } from '@civic/common-gateway-react';
import * as R from 'ramda';
import { chainImplementation } from './chainImplementation';
import { ownerSignsEnabled } from './config';
const logDebug = prefixLogger('SolanaGatewayProvider').debug;
const logInfo = prefixLogger('SolanaGatewayProvider').info;
export const SolanaGatewayProvider = ({ children = null, wallet, connection, cluster = 'mainnet-beta', gatekeeperNetwork, wrapper, logo, stage = 'prod', redirectUrl, broadcastTransaction, gatekeeperSendsTransaction, handleTransaction, expiryMarginSeconds, options = { autoShowModal: true }, partnerAppId, }) => {
    var _a, _b, _c;
    const [chainImpl, setChainImpl] = useState();
    // default to true in case the passed wallet doesn't support the 'connected' property
    const walletIsConnected = (wallet === null || wallet === void 0 ? void 0 : wallet.connected) === undefined ? true : wallet === null || wallet === void 0 ? void 0 : wallet.connected;
    /**
     * Handle the wallet changing, clean up existing listeners
     */
    useEffect(() => {
        var _a;
        if (chainImpl) {
            const chainImplInitProps = {
                network: cluster,
                owner: (_a = wallet === null || wallet === void 0 ? void 0 : wallet.publicKey) === null || _a === void 0 ? void 0 : _a.toBase58(),
                gatekeeperNetwork: gatekeeperNetwork === null || gatekeeperNetwork === void 0 ? void 0 : gatekeeperNetwork.toBase58(),
                stage,
            };
            if (!R.equals(chainImpl.initProps, chainImplInitProps)) {
                logDebug(`useEffect existing chain implementation props are different, calling chainImpl.onDestroy`, {
                    existingInitProps: chainImpl.initProps,
                    newInitProps: chainImplInitProps,
                });
                chainImpl.onDestroy();
            }
        }
    }, [
        walletIsConnected,
        chainImpl,
        connection === null || connection === void 0 ? void 0 : connection.rpcEndpoint,
        (_a = wallet === null || wallet === void 0 ? void 0 : wallet.publicKey) === null || _a === void 0 ? void 0 : _a.toBase58(),
        cluster,
        gatekeeperNetwork === null || gatekeeperNetwork === void 0 ? void 0 : gatekeeperNetwork.toBase58(),
        stage,
    ]);
    useEffect(() => {
        if ((wallet === null || wallet === void 0 ? void 0 : wallet.publicKey) && walletIsConnected && (wallet === null || wallet === void 0 ? void 0 : wallet.signTransaction) && gatekeeperNetwork) {
            const { publicKey, signTransaction, signMessage } = wallet;
            return setChainImpl(chainImplementation({
                connection,
                cluster,
                publicKey,
                signTransaction,
                signMessage,
                gatekeeperNetworkAddress: gatekeeperNetwork,
                stage,
                handleTransaction,
            }));
        }
        setChainImpl(undefined);
    }, [connection, cluster, (_b = wallet === null || wallet === void 0 ? void 0 : wallet.publicKey) === null || _b === void 0 ? void 0 : _b.toBase58(), gatekeeperNetwork === null || gatekeeperNetwork === void 0 ? void 0 : gatekeeperNetwork.toBase58(), stage, walletIsConnected]);
    const walletAdapter = useMemo(() => {
        if ((wallet === null || wallet === void 0 ? void 0 : wallet.publicKey) && walletIsConnected) {
            const { publicKey } = wallet;
            return { publicKey: publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58() };
        }
        return undefined;
    }, [(_c = wallet === null || wallet === void 0 ? void 0 : wallet.publicKey) === null || _c === void 0 ? void 0 : _c.toBase58(), walletIsConnected]);
    const ownerSigns = ownerSignsEnabled({ broadcastTransaction, gatekeeperSendsTransaction });
    logInfo('Client Options', {
        walletPublicKey: walletAdapter === null || walletAdapter === void 0 ? void 0 : walletAdapter.publicKey,
        wallet,
        stage,
        chainImplementation: chainImpl,
        gatekeeperNetwork: gatekeeperNetwork === null || gatekeeperNetwork === void 0 ? void 0 : gatekeeperNetwork.toBase58(),
        redirectUrl,
        ownerSigns,
        options,
        expiryMarginSeconds,
        partnerAppId,
    });
    return (_jsx(GatewayProvider, Object.assign({ wallet: walletAdapter, stage: stage, chainImplementation: chainImpl, gatekeeperNetwork: gatekeeperNetwork === null || gatekeeperNetwork === void 0 ? void 0 : gatekeeperNetwork.toBase58(), wrapper: wrapper, logo: logo, redirectUrl: redirectUrl, ownerSigns: ownerSigns, options: options, expiryMarginSeconds: expiryMarginSeconds, partnerAppId: partnerAppId }, { children: children })));
};
export { useSolanaGateway } from './chainImplementation';
export * from './types';
