import { from as keyFrom, JWK } from '@trust/keyto';
import logger from '../logger';

/**
 * Converts a key in JWK format to hex.
 * EC keys are returned in their compressed form.
 *
 * First tries the @trust/keyto lib, as that supports a broad range of JWK types.
 * However, it does not support CRV: Secp256k1 ( see https://github.com/EternalDeiwos/keyto/issues/11 )
 * Therefore we fall back to our own custom logic (which supports secp256k1) if the keyto lib can't handle the key.
 *
 * @see {@link https://www.npmjs.com/package/@trust/keyto}
 * @see {@link https://github.com/EternalDeiwos/keyto/issues/11}
 *
 *
 * @param jwk The JWK object (usually taken from a verificationMethod in a DID document)
 * @returns The hex representation of the public key
 */
export const jwkToPublicKeyHex = (jwk: JsonWebKey): string => {
  // First try to convert using the @trust/keyto lib which supports a broad range of JWK types:
  try {
    // toString only supports pem here, not hex.
    // public_pkcs1 because that does not include all of the metadata about algorithm type etc that public_pkcs1 does.
    // We only want the key material, not all the PEM metadata.
    const pemKey = keyFrom(jwk as JWK, 'jwk').toString('pem', 'public_pkcs1');
    const pubkeyBuffer = pemBase64ToBuffer(pemKey);

    // We want the key in hex format, not PEM
    return pubkeyBuffer.toString('hex');
  } catch {
    logger.debug('@trust/keyto lib does not recognize JWK format. Falling back to our own custom logic', { jwk });
  }

  // The keyto lib couldn't handle this jwk. It likely has crv: secp256k1
  // (see https://github.com/EternalDeiwos/keyto/issues/11)
  // Try our own logic that only supports EC and OKP with Ed25519 and Secp256k1 curves.
  switch (jwk.kty?.toUpperCase()) {
    case 'EC': {
      return ecKeyToString(jwk);
    }
    case 'OKP': {
      return Buffer.from(jwk.x, 'base64').toString('hex');
    }
    default:
      throw new Error(`Unsupported kty ${jwk.kty} in jwk`);
  }
};

const ecKeyToString = (jwk: JsonWebKey): string => {
  // For Secp256k1, CRV can be either 'Secp256k1' or 'K-256'. The standard isn't clear on that yet.
  if (!['secp256k1', 'k-256', 'ed25519'].includes(jwk.crv?.toLowerCase())) {
    throw new Error(`Unsupported curve ${jwk.crv} for EC jwk`);
  }
  const x = Buffer.from(jwk.x, 'base64');
  const y = Buffer.from(jwk.y, 'base64');
  const isYEven = y[y.length - 1] % 2 === 0;

  return (isYEven ? '02' : '03') + x.toString('hex');
};

const pemBase64ToBuffer = (pem: string): Buffer => {
  // Strip off the PEM header and foooter:
  const base64 = pem
    // Handle both CR and LF line endings
    .split(/\r?\n/)
    .filter((x) => !x.startsWith('---'))
    .join('')
    .trim();
  return Buffer.from(base64, 'base64');
};
