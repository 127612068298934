/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
// eslint-disable @typescript-eslint/no-empty-function
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Keypair, Transaction } from '@solana/web3.js';
import bs58 from 'bs58';
import { WalletAdapter } from '@civic/solana-gateway-react';
import logger from '../../logger';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export type InsecureWalletAdapterWithKeys = WalletAdapter & {
  keys: Keypair; // we need to store the keys in the demo app for testing
  isDemo: boolean;
};

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import nacl from 'tweetnacl';
import { useMultiWallet } from '../../hooks/useWallet';

const adapterWalletToDemoAppWallet = (
  adapterWallet: WalletAdapter,
  signTransaction?: (transaction: Transaction) => Promise<Transaction>,
  signMessage?: (message: Uint8Array) => Promise<Uint8Array>
): WalletAdapter | null => {
  if (!adapterWallet.publicKey || !signTransaction) return null;
  return {
    connected: adapterWallet.connected,
    publicKey: adapterWallet.publicKey,
    signTransaction,
    signMessage,
  };
};

type WalletContextProps = {
  wallet: WalletAdapter | InsecureWalletAdapterWithKeys | undefined;
  generate: () => void;
  setWallet: (wallet: WalletAdapter | InsecureWalletAdapterWithKeys | undefined) => void;
  disconnect: () => void;
  exportB58SecretKey: () => string | null;
  walletFromSecret: (secretKey: string) => InsecureWalletAdapterWithKeys;
  generatedWallet: InsecureWalletAdapterWithKeys | undefined;
};

const walletFromKeys = (keys: Keypair): InsecureWalletAdapterWithKeys => ({
  keys,
  signMessage: (message: Uint8Array) => {
    // return Promise.resolve(nacl.sign.detached(message, keys.secretKey));
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: 'Confirm transaction',
        message: 'Sign Message? This is the demo app equivalent of a connected wallet confirmation dialog',
        buttons: [
          {
            label: 'Confirm Sign Message',
            onClick: () => {
              logger.debug('!!!signMessage after confirm');
              const response = nacl.sign.detached(message, keys.secretKey);
              return resolve(response);
            },
          },
          {
            label: 'Cancel',
            onClick: () => reject(new Error('User rejected sign message')),
          },
        ],
      });
    });
  },
  signTransaction: async (transaction: Transaction): Promise<Transaction> => {
    logger.debug('!!!signTransaction before confirm');
    return new Promise((resolve, reject) => {
      confirmAlert({
        title: 'Confirm transaction',
        message: 'Sign transaction? This is the demo app equivalent of a connected wallet confirmation dialog',
        buttons: [
          {
            label: 'Confirm',
            onClick: () => {
              logger.debug('!!!signTransaction after confirm');
              transaction.partialSign(keys);
              return resolve(transaction);
            },
          },
          {
            label: 'Cancel',
            onClick: () => reject(new Error('User rejected sign transaction')),
          },
        ],
      });
    });
  },
  connected: !!keys.publicKey, // the generated wallet is connected if it has a public key available
  publicKey: keys.publicKey,
  isDemo: true,
});

export const walletFromSecret = (secretKey: string): InsecureWalletAdapterWithKeys => {
  const keys = Keypair.fromSecretKey(bs58.decode(secretKey));
  return walletFromKeys(keys);
};

const WalletContext = React.createContext<WalletContextProps>({
  wallet: undefined,
  generate: () => {},
  setWallet: () => {},
  disconnect: () => {},
  exportB58SecretKey: () => '',
  walletFromSecret: (secretKey: string) => walletFromSecret(secretKey),
  generatedWallet: undefined,
});

const makeNewWallet = (): InsecureWalletAdapterWithKeys => {
  const keys = Keypair.generate();
  return walletFromKeys(keys);
};
type Props = {
  children: JSX.Element | null;
  network?: WalletAdapterNetwork;
};
export const DemoWalletProvider: React.FC<Props> = ({ children = null }: Props) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [generatedWallet, setGeneratedWallet] = useState<InsecureWalletAdapterWithKeys | undefined>();
  const { solanaWallet: adapterWallet } = useMultiWallet();
  const [wallet, setWallet] = useState<WalletAdapter | InsecureWalletAdapterWithKeys | undefined>();
  const generate = useCallback(() => {
    const newWallet = makeNewWallet();
    setWallet(newWallet);
    setGeneratedWallet(newWallet);
  }, []);

  const disconnect = useCallback(() => {
    adapterWallet?.disconnect();
    setWallet(undefined);
    setGeneratedWallet(undefined);
  }, [adapterWallet]);

  const signTransaction = adapterWallet?.signTransaction;
  const signMessage = adapterWallet?.signMessage;
  const adapterWalletConnected = adapterWallet?.connected;

  const exportB58SecretKey = useCallback(() => {
    const keys = (wallet as InsecureWalletAdapterWithKeys)?.keys;
    if (keys) {
      return bs58.encode(keys.secretKey);
    }
    return null;
  }, [wallet]);
  useEffect(() => {
    logger.debug('DemoWalletProvider useEffect', {
      publicKey: adapterWallet?.publicKey?.toBase58(),
      adapterWallet,
      adapterWalletConnected,
    });
    if (adapterWallet) {
      const adaptedWallet = adapterWalletToDemoAppWallet(adapterWallet, signTransaction, signMessage);
      if (adaptedWallet && adapterWalletConnected) {
        logger.debug('setting wallet to adaptedWallet', adaptedWallet);
        setWallet(adaptedWallet);
        return;
      }
    }
    if (!adapterWallet?.publicKey && !adapterWalletConnected && !generatedWallet) {
      setWallet(undefined);
    }
    logger.debug('No adapter wallet');
    const secretKey = urlSearchParams.get('secretKey');
    if (secretKey) {
      const keys = Keypair.fromSecretKey(bs58.decode(secretKey));
      logger.debug('keys', keys);
      const initialWallet = walletFromKeys(keys);
      logger.debug('setting initial wallet', initialWallet);
      setWallet(initialWallet);
    }
  }, [
    setWallet,
    adapterWallet?.publicKey?.toBase58(),
    adapterWalletConnected,
    adapterWallet,
    signTransaction,
    signMessage,
    generatedWallet,
  ]);

  return (
    <WalletContext.Provider
      value={{
        wallet,
        generate,
        setWallet,
        disconnect,
        exportB58SecretKey,
        walletFromSecret,
        generatedWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

DemoWalletProvider.defaultProps = {
  network: undefined,
};

export const useWallet = (): WalletContextProps => useContext(WalletContext);
