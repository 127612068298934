import { DIDDocument } from 'did-resolver';
import axios from 'axios';
import { DIDResolverUrl } from '../types';

export interface DIDResolver {
  resolve(did: string): Promise<DIDDocument>;
}

export default class Resolver implements DIDResolver {
  async resolve(did: string): Promise<DIDDocument> {
    if (did.length === 0) {
      return Promise.reject(new Error('Invalid DID'));
    }
    const didResolverUrl = `${DIDResolverUrl.CIVIC}/${did}`;
    const didResolverResult = await axios.get(didResolverUrl);
    const didDocument = didResolverResult?.data as DIDDocument;
    if (!didDocument) {
      return Promise.reject(new Error('Unable to resolve DID document'));
    }

    return didDocument;
  }
}
