export interface LoggingInterface {
  debug: (message: string, object?: unknown) => void;
  info: (message: string) => void;
  warn: (message: string) => void;
  error: (message: string, exception?: unknown) => void;
}

export class LoggingProvider implements LoggingInterface {
  logger: LoggingInterface;

  constructor(logger: LoggingInterface) {
    this.logger = logger;
  }

  debug(message: string, object?: unknown): void {
    if (object) {
      this.logger.debug(message, object);
    } else {
      this.logger.debug(message);
    }
  }

  info(message: string): void {
    this.logger.info(message);
  }

  warn(message: string): void {
    this.logger.warn(message);
  }

  error(message: string, exception?: unknown): void {
    this.logger.error(message, exception);
  }
}
