import { useCallback, useState, useMemo, useEffect } from 'react';
import logger from '../../logger';
import { icons } from '@civic/civic-chain-icons';
import {
  TESTID_CONNECT_WALLET,
  TESTID_DISCONNECT_WALLET,
  TESTID_POWO_PROOF,
  TESTID_POWO_WALLET,
  TESTID_WALLET_ADDRESS,
} from '../../constants';
import { Options } from '@civic/common-gateway-react';
import { EthereumWalletProvider, signMessage, useEthereumWallet } from './ethereumWallet';
import { IdentityButton, GatewayStatus, useGateway } from '@civic/ethereum-gateway-react';
import { GatewayProvider as EthereumGatewayProvider } from '@civic/ethereum-gateway-react';
import {
  mainnet,
  goerli,
  polygon,
  arbitrum,
  arbitrumSepolia,
  polygonMumbai,
  sepolia,
  fantom,
  fantomTestnet,
  polygonZkEvm,
  polygonZkEvmTestnet,
} from 'wagmi/chains';
import { getAdminPortalUrl } from '../../util';
import { MultichainConnectButton } from '@civic/multichain-connect-react-core';
import { useMultiWallet } from '../../hooks/useWallet';
import { useFeeData } from 'wagmi';

function FeesView(): JSX.Element {
  const { wallet } = useEthereumWallet();
  const feesData = useFeeData({
    formatUnits: 'ether',
  });

  return wallet?.address ? (
    <div>
      <h3>Chain Fees</h3>
      <div>
        <span>Gas Price: </span>
        <span data-testid="FEE_DATA_GAS_PRICE">{feesData.data?.formatted.gasPrice}</span>
      </div>
      <div>
        <span>Max Fee Per Gas: </span>
        <span data-testid="MAX_FEE_PER_GAS">{feesData.data?.formatted.maxFeePerGas}</span>
      </div>
      <div>
        <span>Max Priority Fee Per Gas: </span>
        <span data-testid="MAX_PRIORITY_FEE_PER_GAS">{feesData.data?.formatted.maxPriorityFeePerGas}</span>
      </div>
    </div>
  ) : (
    <></>
  );
}
export function EthereumGatewayStatusView(): JSX.Element {
  const { generate, wallet, disconnect, stage } = useEthereumWallet();
  const { connected } = useMultiWallet();
  const [powoProof, setPowoProof] = useState<string>();
  const { gatewayStatus } = useGateway();

  const proveOwnership = useCallback(async () => {
    if (wallet && wallet.signer) {
      logger.debug('proveOwnership');
      const proof = await signMessage(wallet.signer, stage);
      setPowoProof(proof);
    }
  }, [wallet, setPowoProof]);

  const adminPortalUrl = useMemo(() => {
    const url = wallet?.address && getAdminPortalUrl(stage, wallet?.address);
    return url || undefined;
  }, [stage, wallet?.address]);

  useEffect(() => {
    logger.debug('EthereumGatewayStatusView gatewayStatus', GatewayStatus[gatewayStatus]);
  }, [gatewayStatus]);

  logger.debug(`EthereumGatewayStatusView top-level address: ${wallet?.address}`, { connected });
  return (
    <div className="app-info">
      {!connected && !wallet?.address && (
        <button data-testid={TESTID_CONNECT_WALLET} onClick={generate}>
          Generate and connect wallet
        </button>
      )}
      <FeesView />
      {wallet?.address && (
        <>
          <h4>
            <span>Wallet: </span>
            <span data-testid={TESTID_WALLET_ADDRESS}>{wallet?.address}</span>
            <span>&nbsp;</span>
            {adminPortalUrl && (
              <a href={adminPortalUrl} target="_blank" data-testid="ADMIN_PORTAL_LINK">
                Admin portal
              </a>
            )}
          </h4>
        </>
      )}
      <div>
        <IdentityButton />
      </div>

      {wallet && (
        <div>
          <button data-testid={TESTID_POWO_WALLET} onClick={proveOwnership}>
            Prove Wallet Ownership
          </button>
          <button data-testid={TESTID_DISCONNECT_WALLET} onClick={disconnect}>
            Disconnect Wallet
          </button>
          {powoProof && (
            <div>
              <textarea
                style={{ width: '900px', minHeight: '50px' }}
                data-testid={TESTID_POWO_PROOF}
                readOnly
                value={powoProof}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export const EthereumWalletAndProvider: React.FC<{
  children: JSX.Element;
  gatekeeperNetworkAddress: string;
  gatekeeperSendsTransaction: boolean;
  stage: string;
  options: Options;
}> = ({ children = undefined, gatekeeperNetworkAddress, stage, gatekeeperSendsTransaction, options }) => {
  const { wallet } = useEthereumWallet();
  return (
    <>
      <MultichainConnectButton />
      <>
        {wallet?.address ? (
          <EthereumGatewayProvider
            gatekeeperNetwork={gatekeeperNetworkAddress}
            wallet={wallet}
            stage={stage}
            gatekeeperSendsTransaction={gatekeeperSendsTransaction}
            options={options}
          >
            {children}
          </EthereumGatewayProvider>
        ) : (
          <>{children}</>
        )}
      </>
    </>
  );
};

export const EthereumConnectionAndProvider: React.FC<{ children; stage; network: string }> = ({
  children,
  stage,
  network,
}) => {
  return (
    <header className="App-header">
      <EthereumWalletProvider network={network} stage={stage}>
        {children}
      </EthereumWalletProvider>
    </header>
  );
};

export const xdcMainnet = {
  id: 50,
  name: 'XDC',
  network: 'xdcMainnet',
  iconUrl: icons.XDC,
  nativeCurrency: {
    decimals: 18,
    name: 'XDC',
    symbol: 'XDC',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.xinfin.network'],
    },
    public: {
      http: ['https://rpc.xinfin.network'],
    },
  },
  blockExplorers: {
    default: { name: 'BlocksScan', url: 'https://explorer.xinfin.network' },
    etherscan: { name: 'BlocksScan', url: 'https://explorer.xinfin.network' },
  },
  testnet: false,
};

export const xdcApothem = {
  id: 51,
  name: 'XDC Apothem',
  network: 'xdcApothem',
  iconUrl: icons.XDC,
  nativeCurrency: {
    decimals: 18,
    name: 'TXDC',
    symbol: 'TXDC',
  },
  rpcUrls: {
    default: {
      http: ['https://erpc.apothem.network'],
    },
    public: {
      http: ['https://erpc.apothem.network'],
    },
  },
  blockExplorers: {
    default: { name: 'BlocksScan', url: 'https://explorer.apothem.network' },
    etherscan: { name: 'BlocksScan', url: 'https://explorer.apothem.network' },
  },
  testnet: true,
};
// rainbowkit doesn't expose the SVGs and doesn't provide a default icon for Arbitrum Goerli
export const extendedAribtrumSepolia = {
  ...arbitrumSepolia,
};

const extendedFantomMainnet = {
  ...fantom,
  iconUrl: icons.FTM,
};

const extendedFantomTestnet = {
  ...fantomTestnet,
  iconUrl: icons.FTM,
};

export const chains = {
  ethereum: mainnet,
  goerli,
  polygon,
  polygonMumbai,
  xdcMainnet,
  xdcmainnet: xdcMainnet,
  xdcApothem,
  'arbitrum one': arbitrum,
  arbitrumSepolia: extendedAribtrumSepolia,
  fantom: extendedFantomMainnet,
  fantomTestnet: extendedFantomTestnet,
  polygonZkEvm,
  polygonZkEvmTestnet,
};

export const defaultEvmChains = [polygon, mainnet, xdcMainnet, arbitrum, extendedFantomMainnet, polygonZkEvm];

export const defaultEvmTestChains = [
  sepolia,
  polygonMumbai,
  goerli,
  xdcApothem,
  extendedAribtrumSepolia,
  extendedFantomTestnet,
  polygonZkEvmTestnet,
];

export const evmChains = [...defaultEvmChains, ...defaultEvmTestChains];
export const evmNetworks = Object.values(evmChains).map((evmChain) => evmChain.name.toLowerCase());

export const chainIdFromNetwork = (networkName: string): number | undefined => {
  const chain = evmChains.find((evmChain) => evmChain.name.toLowerCase() === networkName.toLowerCase());
  return chain?.id;
};

export const ethereumNetworkFromStage = (inStage: string): string => {
  switch (inStage) {
    case 'prod':
      return defaultEvmChains[0].name;
    default:
      return defaultEvmTestChains[0].name;
  }
};
