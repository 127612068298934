import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  GatewayStatus,
  useGateway,
  IdentityButton,
  getTokenDescription,
  WalletAdapter,
} from '@civic/solana-gateway-react';
import { useWallet, InsecureWalletAdapterWithKeys, DemoWalletProvider } from './solanaWallet';
import logger from '../../logger';
import { Connection } from '@solana/web3.js';
import {
  TESTID_AIRDROP,
  TESTID_CIVIC_SIGN_NONCE,
  TESTID_CIVIC_SIGN_PROOF,
  TESTID_CONNECT_WALLET,
  TESTID_DISCONNECT_WALLET,
  TESTID_GATEWAY_STATUS,
  TESTID_GATEWAY_TOKEN,
  TESTID_POWO_PROOF,
  TESTID_POWO_WALLET,
  TESTID_RECONNECT_WALLET,
  TESTID_REQUEST_TOKEN,
  TESTID_SECRET_KEY,
  TESTID_SHOW_SECRET_KEY,
  TESTID_WALLET_ADDRESS,
} from '../../constants';
import {
  SignedProof,
  CivicSignProveFactory,
  CivicSignProve,
  verify,
  SolanaSignedProofOptions,
} from '@civic/civic-sign';
import {
  clusterEndpoint,
  ExtendedCluster,
  getAdminPortalUrl,
  LAMPORTS_PER_SOL,
  wrapperOptionsFromSelect,
} from '../../util';
import toast, { Toaster } from 'react-hot-toast';
import { Badge, GatewayProvider as SolanaGatewayProvider, Options } from '@civic/solana-gateway-react';
import { PublicKey, Transaction } from '@solana/web3.js';
import { SolanaWalletAdapter } from '@civic/solana-gateway-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

import axios from 'axios';
import { MultichainConnectButton } from '@civic/multichain-connect-react-core';
type PowoProof = {
  did?: string;
  proof: string;
  civicSignProof?: SignedProof;
  verified?: boolean;
};
type Nonce = { nonce: string; timestamp: number };
const getNonce = async (civicPassApiStage: string): Promise<Nonce> => {
  const response = await axios.get<Nonce>(`https://dev.api.civic.com/sign-${civicPassApiStage}/nonce`);
  return response.data;
};
export const useSolanaConnectionParams = (chain, network: string) => {
  const clusterNetwork = useMemo(
    () => (['devnet', 'testnet', 'mainnet-beta', 'civicnet', 'localnet'].includes(network) ? network : 'civicnet'),
    [network]
  );
  const solanaEndpoint = useMemo(() => clusterEndpoint(clusterNetwork as ExtendedCluster), [clusterNetwork]);
  const solanaConnection = useMemo(() => {
    if (chain === 'solana') {
      return new Connection(solanaEndpoint, 'processed');
    }
    return null;
  }, [solanaEndpoint]);
  return { solanaConnection, solanaEndpoint };
};

const timeToSeconds = (time: number) => Math.floor(time / 1000);
export const toISO = (timestamp: number): string => new Date(timestamp * 1000).toISOString();
export const parseTimestamp = (timestamp: number | undefined): string | undefined =>
  !timestamp ? undefined : toISO(timestamp);

const requiresSignature = (transaction: Transaction, publicKey: PublicKey) => {
  return transaction.signatures.find((sig) => sig.publicKey.equals(publicKey)) !== undefined;
};

const submitTransaction = async (connection: Connection, transaction: Transaction) => {
  const txSig = await connection.sendRawTransaction(transaction.serialize());

  // override connection commitment as we will poll/listen for confirmation of the token being created/refreshed
  const blockhash = await connection.getLatestBlockhash('processed');
  await connection.confirmTransaction({ ...blockhash, signature: txSig }, 'processed');
  return txSig;
};

const retriesForPublicKeys = {};
const handleSolanaTransaction = async (
  connection: Connection,
  wallet: SolanaWalletAdapter | InsecureWalletAdapterWithKeys | undefined,
  transaction: Transaction,
  sendDelay = 0,
  sendNoDelayAfterRetries = 1
) => {
  if (!wallet || !wallet.signTransaction || !wallet.publicKey) {
    throw new Error('Wallet must have a public key');
  }
  let transactionToSend: Transaction = transaction;
  if (requiresSignature(transaction, wallet.publicKey)) {
    transactionToSend = await wallet.signTransaction(transaction);
  }
  const publicKeyStr = wallet?.publicKey.toBase58();
  if (!retriesForPublicKeys[publicKeyStr]) {
    retriesForPublicKeys[publicKeyStr] = 0;
  }
  const useDelay = retriesForPublicKeys[publicKeyStr] < sendNoDelayAfterRetries;
  console.log('handleSolanaTransaction', { retriesForPublicKeys, sendDelay, useDelay });
  let txSig = '';
  setTimeout(
    async () => {
      if (wallet?.publicKey) {
        console.log('handleSolanaTransaction AFTER sendDelay', { sendDelay, retriesForPublicKeys });
        txSig = await submitTransaction(connection, transactionToSend);
      }
    },
    useDelay ? sendDelay * 1000 : 0
  );
  retriesForPublicKeys[publicKeyStr] += 1;
  return txSig;
};

export function SolanaGatewayStatusView({
  setIFrameUrl,
  stage,
  network,
  connection,
}: {
  setIFrameUrl?: React.Dispatch<React.SetStateAction<string | undefined>>;
  stage: string;
  network: ExtendedCluster;
  connection: Connection | null;
}): JSX.Element {
  const { gatewayStatus, gatewayToken, civicPassSrcUrl, pendingRequests } = useGateway();
  const [gatewayTokenExpiry, setGatewayTokenExpiry] = useState<number>();
  const { generatedWallet, generate, wallet, disconnect, setWallet, exportB58SecretKey, walletFromSecret } =
    useWallet();
  const [previousWallet, setPreviousWallet] = useState<WalletAdapter | InsecureWalletAdapterWithKeys>();
  const [powoProof, setPowoProof] = useState<PowoProof>();
  const [civicSign, setCivicSign] = useState<CivicSignProve>();
  const [balance, setBalance] = useState<string>();
  const [nonce, setNonce] = useState<Nonce>();

  const options: SolanaSignedProofOptions = {
    cluster: 'mainnet-beta',
    supportedClusterUrls: {
      'mainnet-beta':
        'https://twilight-small-flower.solana-mainnet.quiknode.pro/ec921b46257fea7637f4caed5780c80d49f4ab26/',
    },
    recentBlockCheck: false,
    commitment: 'confirmed',
    broadcastCheck: false,
  };

  useEffect(() => {
    setIFrameUrl && setIFrameUrl(civicPassSrcUrl);
  }, [civicPassSrcUrl, setIFrameUrl]);

  const verifyCivicSignProof = useCallback(async () => {
    if (powoProof && powoProof.civicSignProof && powoProof.did && nonce) {
      try {
        console.log('verifying...', powoProof.civicSignProof);
        const proof = { ...powoProof.civicSignProof, options };
        await verify(powoProof.did, proof, nonce.nonce);
        setPowoProof({
          ...powoProof,
          verified: true,
        });
      } catch (error) {
        console.error(error);
        setPowoProof({
          ...powoProof,
          verified: false,
        });
      }
    }
    return null;
  }, [powoProof, setPowoProof, nonce]);

  const proveOwnership = useCallback(async () => {
    if (wallet) {
      logger.debug('proveOwnership');
      const did = await civicSign?.requestDid();
      logger.debug('DID: ', did);
      const civicSignNonce = await getNonce('dev');
      setNonce(civicSignNonce);
      const civicSignProof = await civicSign?.requestProof(civicSignNonce.nonce);
      if (civicSignProof && did) {
        logger.debug('powo proof', civicSignProof.proof);
        setPowoProof({ proof: civicSignProof?.proof, civicSignProof, did: did?.did });
      }
    }
  }, [wallet, setPowoProof, civicSign]);
  const selectPreviousWallet = useCallback(() => {
    setWallet(previousWallet);
  }, [setWallet, previousWallet]);

  const [secretKeyShown, setSecretKeyShown] = useState<boolean>(true);
  const showSecretKey = useCallback(() => {
    if (wallet) {
      setSecretKeyShown(true);
    }
  }, [wallet]);
  const getSecretKey = useCallback(() => {
    if (wallet) {
      return exportB58SecretKey() || '';
    }
    return '';
  }, [wallet, exportB58SecretKey]);

  const setSecretKey = useCallback(
    (event: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) => {
      console.log('setSecretKey event', event.currentTarget.value);
      if (event.currentTarget.value)
        setWallet(walletFromSecret(event.currentTarget.value as string) as InsecureWalletAdapterWithKeys);
    },
    [walletFromSecret, setWallet]
  );

  useEffect(() => {
    logger.debug('GatewayStatusView render', {
      gatewayStatus: GatewayStatus[gatewayStatus],
    });
  }, [gatewayStatus]);

  const updateBalance = useCallback(
    async (connection) => {
      if (wallet && wallet.publicKey) {
        const balance = await connection.getBalance(wallet.publicKey);
        setBalance(`${balance / LAMPORTS_PER_SOL}`);
        return balance;
      }
    },
    [wallet, setBalance]
  );
  const { solanaEndpoint } = useSolanaConnectionParams('solana', network);
  const airdropToWalletIfNeeded = useCallback(async () => {
    if (wallet && wallet.publicKey && connection) {
      console.log('Airdropping airdropToWalletIfNeeded', {
        wallet,
        stage,
        network,
        connection: connection.rpcEndpoint,
      });
      console.log('Airdropping airdropToWalletIfNeeded endpoint', { solanaEndpoint });
      const useBalance = await updateBalance(connection);
      if (!network.includes('mainnet')) {
        console.log('Airdropping airdropToWalletIfNeeded balance', useBalance);
        if (useBalance / LAMPORTS_PER_SOL < 0.1) {
          try {
            console.log('Airdropping connection.requestAirdrop...', connection.rpcEndpoint);
            const blockhash = await connection.getLatestBlockhash();
            const txId = await connection.requestAirdrop(wallet.publicKey, LAMPORTS_PER_SOL * 0.1);
            await connection.confirmTransaction({ ...blockhash, signature: txId });
            console.log('Airdropping connection.requestAirdrop finished');
            await updateBalance(connection);
            toast.success(`Successfully airdropped 0.1 SOL to ${wallet.publicKey.toBase58()}`, { duration: 4000 });
          } catch (error) {
            toast.error(
              `Error airdropping 0.1 SOL to ${wallet.publicKey.toBase58()} on ${connection.rpcEndpoint}: ${
                (error as Error).message
              }`,
              {
                duration: 4000,
              }
            );
          }
        }
      }
    }
  }, [wallet?.publicKey, stage, network, connection?.rpcEndpoint, solanaEndpoint]);

  useEffect(() => {
    if (wallet?.publicKey) {
      airdropToWalletIfNeeded();
    }
  }, [wallet?.publicKey?.toBase58(), network]);

  useEffect(() => {
    if (wallet && wallet.publicKey && wallet.signTransaction) {
      console.log('useEffect wallet has changed, updating CivicSign proof', wallet.publicKey.toBase58());
      const civicSignInst = CivicSignProveFactory.createWithSolanaWallet(
        {
          publicKey: wallet?.publicKey,
          signTransaction: wallet?.signTransaction,
          signMessage: wallet?.signMessage,
        },
        options
      );
      setCivicSign(civicSignInst);
      setPreviousWallet(wallet);
      if ((wallet as InsecureWalletAdapterWithKeys).isDemo) return;
      setSecretKeyShown(false);
    } else {
      setPowoProof(undefined);
      setSecretKeyShown(true);
      setCivicSign(undefined);
    }
  }, [previousWallet, wallet?.publicKey, setCivicSign, setSecretKeyShown]);

  let gatewayTokenExpiryInterval;
  useEffect(() => {
    const abortController = new AbortController();
    if (gatewayToken && gatewayToken.expiryTime) {
      gatewayTokenExpiryInterval = setInterval(() => {
        if (!abortController.signal.aborted && gatewayToken.expiryTime) {
          setGatewayTokenExpiry(
            timeToSeconds(new Date(gatewayToken.expiryTime * 1000).getTime() - new Date().getTime())
          );
        }
      }, 1000);
    } else if (gatewayTokenExpiryInterval) {
      clearTimeout(gatewayTokenExpiryInterval);
    }
    return () => abortController.abort();
  }, [gatewayToken]);

  const adminPortalUrl = useMemo(() => {
    const url = wallet?.publicKey && getAdminPortalUrl(stage, wallet.publicKey.toBase58());
    return url || undefined;
  }, [stage, wallet]);

  return (
    <div className="app-info">
      {wallet && <IdentityButton data-testid={TESTID_REQUEST_TOKEN} />}
      {wallet && wallet.publicKey && (
        <>
          <h4>
            <span>Wallet: </span>
            <span data-testid={TESTID_WALLET_ADDRESS}>{wallet.publicKey.toBase58()}</span>
            <span> ({balance} SOL)</span>
            <span>&nbsp;</span>
            {adminPortalUrl && (
              <a href={adminPortalUrl} target="_blank" data-testid="ADMIN_PORTAL_LINK">
                Admin portal
              </a>
            )}
          </h4>
        </>
      )}

      {wallet && powoProof?.did && (
        <div>
          <h4>
            DID: <span data-testid={TESTID_WALLET_ADDRESS}>{powoProof.did}</span>
          </h4>
        </div>
      )}
      <h3>
        Gateway Status: <span data-testid={TESTID_GATEWAY_STATUS}>{GatewayStatus[gatewayStatus]}</span>
      </h3>
      <h4>
        Pending Requests:
        <>
          {pendingRequests &&
            Object.entries(pendingRequests).map(([key, value]) => (
              <span key={key}>
                {key}: {JSON.stringify(value)}
              </span>
            ))}
        </>
      </h4>

      <small>{getTokenDescription(gatewayStatus)}</small>
      {gatewayToken && (
        <h4>
          GatewayToken: <span data-testid={TESTID_GATEWAY_TOKEN}>{gatewayToken.publicKey.toBase58()}</span>
        </h4>
      )}
      {gatewayToken && (
        <>
          <h4>
            GatewayToken Expiry: <span>{parseTimestamp(gatewayToken.expiryTime)}</span>
          </h4>
          <h4>
            Seconds to expiry: <span>{gatewayTokenExpiry}</span>
          </h4>
        </>
      )}
      {!generatedWallet && (
        <div>
          <MultichainConnectButton />
        </div>
      )}
      {!wallet && (
        <button data-testid={TESTID_CONNECT_WALLET} className="button" onClick={generate}>
          Generate and connect wallet
        </button>
      )}
      {previousWallet && !wallet && (
        <button data-testid={TESTID_RECONNECT_WALLET} onClick={selectPreviousWallet}>
          Re-Connect Wallet
        </button>
      )}
      {wallet && (
        <div>
          <button
            data-testid={TESTID_AIRDROP}
            disabled={network.includes('mainnet')}
            className="button"
            onClick={airdropToWalletIfNeeded}
          >
            Airdrop
          </button>
          {(wallet as InsecureWalletAdapterWithKeys).isDemo && (
            <button type="button" className="button" data-testid={TESTID_SHOW_SECRET_KEY} onClick={showSecretKey}>
              Show secret key
            </button>
          )}
          <button data-testid={TESTID_POWO_WALLET} className="button" onClick={proveOwnership}>
            Prove Wallet Ownership
          </button>
          <button data-testid={TESTID_DISCONNECT_WALLET} className="button" onClick={disconnect}>
            Disconnect Wallet
          </button>
        </div>
      )}
      <div className="secret-key">
        {wallet && powoProof?.did && (
          <div>
            <label>DID:</label>
            <textarea style={{ width: '900px' }} data-testid={TESTID_CIVIC_SIGN_PROOF} defaultValue={powoProof.did} />
          </div>
        )}
        {wallet && powoProof?.civicSignProof && (
          <div>
            <div>
              <label>CivicSign Proof:</label>
              <textarea
                style={{ width: '900px' }}
                data-testid={TESTID_CIVIC_SIGN_PROOF}
                defaultValue={JSON.stringify(powoProof.civicSignProof, null, 2)}
              />
            </div>
            <div>
              <label>CivicSign Nonce:</label>
              <textarea
                style={{ width: '900px' }}
                data-testid={TESTID_CIVIC_SIGN_NONCE}
                disabled={true}
                defaultValue={JSON.stringify(nonce)}
              />
            </div>
            <div>
              <button data-testid={TESTID_CONNECT_WALLET} onClick={verifyCivicSignProof}>
                Verify Proof
              </button>
              <span>{`Proof verified: ${powoProof.verified}`}</span>
            </div>
          </div>
        )}
        {wallet && powoProof?.proof && (
          <div>
            <label>Payload Proof:</label>
            <textarea style={{ width: '900px' }} data-testid={TESTID_POWO_PROOF} defaultValue={powoProof.proof} />
          </div>
        )}
        {secretKeyShown && (
          <div>
            <label>Wallet Secret Key:</label>
            <textarea
              data-testid={TESTID_SECRET_KEY}
              style={{ width: '900px' }}
              defaultValue={wallet ? getSecretKey() : ''}
              onClick={(event) => setSecretKey(event)}
            />
          </div>
        )}
      </div>
      <Toaster position="bottom-left" reverseOrder={false} />
    </div>
  );
}

export const SolanaWalletAndProvider: React.FC<{
  children: JSX.Element;
  stage: string;
  gatekeeperNetworkAddress: string;
  chain: string;
  wrapperSelect: string;
  connection: Connection;
  setIFrameUrl?: React.Dispatch<React.SetStateAction<string | undefined>>;
  network: ExtendedCluster;
  setNetwork: React.Dispatch<React.SetStateAction<string>>;
  options: Options;
  gatekeeperSendsTransaction: boolean;
  expiryMarginSeconds?: number;
  clientSendsDelay?: number;
  clientSendsNoDelayOnRetry?: number;
  partnerAppId?: string;
}> = ({
  children,
  stage,
  gatekeeperNetworkAddress,
  connection,
  network,
  options,
  gatekeeperSendsTransaction,
  expiryMarginSeconds,
  clientSendsDelay,
  clientSendsNoDelayOnRetry,
  partnerAppId,
  wrapperSelect,
}) => {
  const { wallet } = useWallet();
  const wrapperOptions = wrapperOptionsFromSelect(wrapperSelect);

  return (
    <>
      {wallet && wallet.publicKey && (
        <Badge
          connection={connection}
          gatekeeperNetwork={new PublicKey(gatekeeperNetworkAddress)}
          publicKey={wallet.publicKey}
        />
      )}
      <SolanaGatewayProvider
        wallet={wallet}
        // stage defaults to prod, but for now we're testing against dev.
        stage={stage}
        // No default for gatekeeperNetwork, in this case we use Civic's dev network.
        gatekeeperNetwork={new PublicKey(gatekeeperNetworkAddress)}
        wrapper={wrapperOptions.wrapper}
        logo={wrapperOptions.logo}
        connection={connection}
        cluster={network}
        options={options}
        gatekeeperSendsTransaction={gatekeeperSendsTransaction}
        expiryMarginSeconds={expiryMarginSeconds}
        handleTransaction={
          clientSendsDelay !== undefined
            ? (transaction: Transaction) =>
                handleSolanaTransaction(connection, wallet, transaction, clientSendsDelay, clientSendsNoDelayOnRetry)
            : undefined
        }
        partnerAppId={partnerAppId}
      >
        {children}
      </SolanaGatewayProvider>
    </>
  );
};

export const solanaNetworkFromStage = (inStage: string): string => {
  switch (inStage) {
    case 'prod':
      return WalletAdapterNetwork.Mainnet;
    case 'local':
      return 'localnet';
    default:
      return 'civicnet';
  }
};

export const solanaNetworks = [
  'localnet',
  'civicnet',
  WalletAdapterNetwork.Devnet,
  WalletAdapterNetwork.Testnet,
  WalletAdapterNetwork.Mainnet,
];
export const SolanaConnectionAndProvider: React.FC<{ children; network }> = ({ children }) => {
  return <DemoWalletProvider>{children}</DemoWalletProvider>;
};

const solanaTestnets: ExtendedCluster[] = ['devnet', 'testnet', 'mainnet-beta', 'civicnet', 'localnet'];
const solanaMainnets: ExtendedCluster[] = ['mainnet-beta'];

export const defaultSolanaChains = solanaMainnets.map((chainName) => {
  return { name: chainName, rpcEndpoint: clusterEndpoint(chainName) };
});
export const defaultSolanaTestChains = solanaTestnets.map((chainName) => {
  return { name: chainName, rpcEndpoint: clusterEndpoint(chainName) };
});
