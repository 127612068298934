import { Deferred } from './utils';
import { EventListener } from './eventListener';
import {
  Chain,
  SignatureMethod,
  Wallet,
  WalletSignedProof,
  EventTypeResponse,
  EventTypeRequest,
  EventTypeRequestWithPayload,
} from '../types';
import logger from './logger';

const DEFAULT_TIMEOUT = 1000;

export function RemoteWallet({
  eventListener,
  chain,
  timeout = DEFAULT_TIMEOUT,
  instanceId,
  debug = false,
}: {
  eventListener: EventListener;
  chain: Chain;
  timeout?: number;
  instanceId: string;
  debug?: boolean;
}): Wallet {
  const logDebug = (message: string, obj: unknown) => debug && logger.debug(`[RemoteWallet] ${message}`, obj);
  const getPublicKeyPromise = new Deferred<string>();
  const getDidPromise = new Deferred<string>();
  const signProofPromise = new Deferred<WalletSignedProof>();
  const signMessagePromise = new Deferred<Uint8Array>();

  let waitForPublicKey: NodeJS.Timeout;
  let waitForDid: NodeJS.Timeout;
  let waitForSignedProof: NodeJS.Timeout;
  let waitForSignedMessage: NodeJS.Timeout;

  const emit = (event: EventTypeRequest) => {
    logger.debug('emitting postMessage event from civic sign', event);
    eventListener.postMessage(event, '*');
  };

  const emitWithPayload = (event: EventTypeRequestWithPayload) => {
    logger.debug('emitting postMessageWithPayload event from civic sign', { ...event, instanceId });
    eventListener.postMessageWithPayload({ ...event, instanceId }, '*');
  };

  function waitForResponse<T>(r: EventTypeRequest, p: Deferred<T>) {
    return setTimeout(() => {
      p.reject(new Error(`No response from remote app for ${r}`));
    }, timeout);
  }

  const supportedEvents = [
    EventTypeResponse.RESPONSE_PUBLIC_KEY,
    EventTypeResponse.RESPONSE_DID,
    EventTypeResponse.RESPONSE_SIGNED_PROOF,
    EventTypeResponse.RESPONSE_SIGNED_MESSAGE,
  ];

  eventListener.addEventListener((response) => {
    if (!supportedEvents.includes(response.event)) {
      return;
    }
    logDebug('Received event from remote app', { instanceId, response });
    if (response.instanceId && response.instanceId !== instanceId) {
      logDebug('Event from remote app with different instanceId, not responding', { instanceId, response });
      return;
    }
    switch (response.event) {
      case EventTypeResponse.RESPONSE_PUBLIC_KEY:
        clearTimeout(waitForPublicKey);
        getPublicKeyPromise.resolve(response.data);
        break;
      case EventTypeResponse.RESPONSE_DID:
        clearTimeout(waitForDid);
        getDidPromise.resolve(response.data);
        break;
      case EventTypeResponse.RESPONSE_SIGNED_PROOF:
        clearTimeout(waitForSignedProof);
        // need to handle the legacy case where events are being emitted by an older version of civic-sign
        // with no signatureMethod
        if (typeof response.data === 'string') {
          signProofPromise.resolve({ chain, proof: response.data, signatureMethod: SignatureMethod.TRANSACTION });
          break;
        }
        signProofPromise.resolve({ chain, signatureMethod: response.data.signatureMethod, proof: response.data.proof });
        break;
      case EventTypeResponse.RESPONSE_SIGNED_MESSAGE:
        clearTimeout(waitForSignedMessage);
        signMessagePromise.resolve(response.data);
        break;
    }
  });

  return {
    getPublicKey: () => {
      waitForPublicKey = waitForResponse(EventTypeRequest.REQUEST_PUBLIC_KEY, getPublicKeyPromise);
      emit(EventTypeRequest.REQUEST_PUBLIC_KEY); // for legacy listeners
      emitWithPayload({ request: EventTypeRequest.REQUEST_PUBLIC_KEY });
      return getPublicKeyPromise.promise;
    },
    getDid: () => {
      waitForDid = waitForResponse(EventTypeRequest.REQUEST_DID, getDidPromise);
      emit(EventTypeRequest.REQUEST_DID); // for legacy listeners
      emitWithPayload({ request: EventTypeRequest.REQUEST_DID });
      return getDidPromise.promise;
    },
    signProof: (message: string) => {
      waitForSignedProof = waitForResponse(EventTypeRequest.REQUEST_SIGNED_PROOF, signProofPromise);
      emit(EventTypeRequest.REQUEST_SIGNED_PROOF); // for legacy listeners
      emitWithPayload({ request: EventTypeRequest.REQUEST_SIGNED_PROOF, payload: message });
      return signProofPromise.promise;
    },
    signMessage: (message: Uint8Array) => {
      waitForSignedMessage = waitForResponse(EventTypeRequest.REQUEST_SIGNED_MESSAGE, signMessagePromise);
      emitWithPayload({ request: EventTypeRequest.REQUEST_SIGNED_MESSAGE, payload: message });
      return signMessagePromise.promise;
    },
    chain,
  };
}
