/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { autoShowIframe } from '../utils/status';
import { prefixLogger } from '../logger';
import { CivicPassIssuanceStatus, CivicPassMessageAction, CivicPassMessageEventResult, GatekeeperAPIStatus, GatewayStatus, ExtendedGatewayStatus, RefreshTokenState, TokenIssuanceState, } from '../types';
import { getCivicPassEndpoint } from '../config';
import { gatewayStatusIfAllowed, hasFetchError, resetState, statusFromToken, validationProcessToGatewayStatus, } from '../useReducer/utils';
import { objectToURLParams } from '../utils/generalUtils';
import { flowTypeFromFlowId, getFlowId } from '../utils/analytics';
const logDebug = prefixLogger('useCivicPass').debug;
const logError = prefixLogger('useCivicPass').error;
const getOriginWithoutPort = (url) => {
    const parsedUrl = new URL(url);
    return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
};
const gatewayStatusToCivicPassAction = {
    [GatewayStatus.PROOF_OF_WALLET_OWNERSHIP]: CivicPassMessageAction.PROOF_OF_WALLET_OWNERSHIP,
    [GatewayStatus.IN_REVIEW]: CivicPassMessageAction.TOKEN_IN_REVIEW,
    [GatewayStatus.ERROR]: CivicPassMessageAction.ERROR,
    [GatewayStatus.ACTIVE]: CivicPassMessageAction.TOKEN_ACTIVE,
    [GatewayStatus.REVOKED]: CivicPassMessageAction.TOKEN_REVOKED,
    [GatewayStatus.FROZEN]: CivicPassMessageAction.TOKEN_FROZEN,
    [GatewayStatus.REJECTED]: CivicPassMessageAction.TOKEN_REJECTED,
    [GatewayStatus.LOCATION_NOT_SUPPORTED]: CivicPassMessageAction.FAILED_IP_CHECK,
    [GatewayStatus.VPN_NOT_SUPPORTED]: CivicPassMessageAction.FAILED_VPN_CHECK,
    [GatewayStatus.REFRESH_TOKEN_REQUIRED]: CivicPassMessageAction.REFRESH,
    [GatewayStatus.CHECKING]: CivicPassMessageAction.STATUS,
    [GatewayStatus.UNKNOWN]: CivicPassMessageAction.ISSUANCE,
    [GatewayStatus.NOT_REQUESTED]: CivicPassMessageAction.ISSUANCE,
    [GatewayStatus.COLLECTING_USER_INFORMATION]: CivicPassMessageAction.ISSUANCE,
    [GatewayStatus.VALIDATING_USER_INFORMATION]: CivicPassMessageAction.ISSUANCE,
    [GatewayStatus.USER_INFORMATION_VALIDATED]: CivicPassMessageAction.ISSUANCE,
    [GatewayStatus.USER_INFORMATION_REJECTED]: CivicPassMessageAction.ISSUANCE,
    [ExtendedGatewayStatus.CONFIRM_OWNER_TRANSACTION]: CivicPassMessageAction.SIGN_TRANSACTION,
    [ExtendedGatewayStatus.AWAITING_OWNER_TRANSACTION]: CivicPassMessageAction.AWAITING_TRANSACTION_CONFIRMATION,
    [ExtendedGatewayStatus.CHAIN_TIMEOUT_ERROR]: CivicPassMessageAction.CHAIN_ERROR,
    [ExtendedGatewayStatus.CHAIN_TRANSACTION_ERROR]: CivicPassMessageAction.CHAIN_ERROR,
    [ExtendedGatewayStatus.TOKEN_IN_PARTNER_REVIEW]: CivicPassMessageAction.TOKEN_IN_PARTNER_REVIEW,
    [ExtendedGatewayStatus.TOKEN_REFRESH_IN_REVIEW]: CivicPassMessageAction.TOKEN_REFRESH_IN_REVIEW,
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCivicPassSrcUrl = (state, status, errorResponse) => {
    const { redirectUrl, gatekeeperNetworkAddress, stage, walletAddress, civicPass, chainType, chainNetwork, ownerSigns, partnerAppId, instanceId, signedTx, flowId, did, } = state;
    const errorResponseIfExists = (errorResponse || civicPass.requestPayload);
    logDebug('getCivicPassSrcUrl', { errorResponseIfExists, civicPass, state });
    if (!gatekeeperNetworkAddress || !walletAddress) {
        logError('Required properties not present', { gatekeeperNetworkAddress, walletAddress });
        return undefined;
    }
    const parentSearchParams = new URLSearchParams((window === null || window === void 0 ? void 0 : window.location.search) || '');
    // allow the parent to override the referrer as GET parameter for cases a noreferrer link has been used
    const referrer = parentSearchParams.get('referrer') || document.referrer;
    const civicPassSrcUrl = getCivicPassEndpoint(stage);
    const url = new URL(civicPassSrcUrl);
    const action = gatewayStatusToCivicPassAction[status];
    const filteredParams = objectToURLParams(Object.assign(Object.assign({}, civicPass.requestPayload), { redirectUrl, networkAddress: gatekeeperNetworkAddress, action, wallet: walletAddress, chain: chainType, chainNetwork: chainNetwork === null || chainNetwork === void 0 ? void 0 : chainNetwork.toString(), ownerSigns: ownerSigns ? 'true' : 'false', errorCode: errorResponseIfExists === null || errorResponseIfExists === void 0 ? void 0 : errorResponseIfExists.errorCode, payload: (errorResponseIfExists === null || errorResponseIfExists === void 0 ? void 0 : errorResponseIfExists.payload) ? JSON.stringify(errorResponseIfExists === null || errorResponseIfExists === void 0 ? void 0 : errorResponseIfExists.payload) : undefined, partnerAppId,
        instanceId,
        signedTx,
        flowId,
        referrer,
        did, domain: window === null || window === void 0 ? void 0 : window.location.hostname, flowType: flowTypeFromFlowId(flowId) }));
    const searchParams = new URLSearchParams(filteredParams);
    logDebug('getCivicPassSrcUrl', { filteredParams });
    return `${url.href}?${searchParams.toString()}`;
};
export const reducer = (state, action) => {
    var _a;
    // the flowId will only be included in the state if if's an issuance or refresh action
    const flowId = getFlowId(action.type, state);
    logDebug(`reducer action.type: ${action.type} flowId: ${flowId}`, action);
    switch (action.type) {
        /** CPASS-1271 These actions are initiated by the RC and are not initiated by the Iframe, they should be moved */
        case 'civicPass_owner_sends_chainTransactionError':
        case 'civicPass_signMessageError': {
            // set refresh token state to failed if in progress
            const refreshTokenState = state.refreshTokenState === RefreshTokenState.IN_PROGRESS ? RefreshTokenState.FAILED : state.refreshTokenState;
            return Object.assign(Object.assign({}, state), { flowId, iframeMinimized: false, renderIframe: true, gatewayStatus: ExtendedGatewayStatus.CHAIN_TRANSACTION_ERROR, refreshTokenState, iframeSrcUrl: getCivicPassSrcUrl(state, ExtendedGatewayStatus.CHAIN_TRANSACTION_ERROR, {
                    errorCode: (_a = action.payload) === null || _a === void 0 ? void 0 : _a.errorCode,
                }) });
        }
        case 'civicPass_check_token_status': {
            const gatewayStatus = statusFromToken(state, action.token || state.gatewayToken);
            logDebug('civicPass_check_token_status', { gatewayStatus: GatewayStatus[gatewayStatus] });
            return Object.assign(Object.assign({}, state), { flowId,
                gatewayStatus, iframeMinimized: !autoShowIframe(state), renderIframe: true, iframeSrcUrl: state.civicPass.status === CivicPassIssuanceStatus.REQUESTED
                    ? state.iframeSrcUrl
                    : getCivicPassSrcUrl(state, gatewayStatus) });
        }
        case 'civicPass_token_in_partner_review_status': {
            return Object.assign(Object.assign({}, state), { flowId, iframeMinimized: !state.userInitiatedFlow, renderIframe: true, gatewayStatus: gatewayStatusIfAllowed(ExtendedGatewayStatus.TOKEN_IN_PARTNER_REVIEW, state), iframeSrcUrl: getCivicPassSrcUrl(state, ExtendedGatewayStatus.TOKEN_IN_PARTNER_REVIEW) });
        }
        case 'civicPass_check_in_progress': {
            return Object.assign(Object.assign({}, state), { flowId, iframeMinimized: !autoShowIframe(state), renderIframe: true, iframeSrcUrl: getCivicPassSrcUrl(state, GatewayStatus.CHECKING), gatewayStatus: gatewayStatusIfAllowed(GatewayStatus.CHECKING, state) });
        }
        case 'civicPass_check_status_complete': {
            const { payload } = action.payload;
            const { status: dataCollectionStatus } = payload;
            const gatewayStatus = state.gatewayToken
                ? statusFromToken(state, state.gatewayToken)
                : gatewayStatusIfAllowed(validationProcessToGatewayStatus[dataCollectionStatus], Object.assign(Object.assign({}, state), { dataCollectionStatus }));
            const iframeSrcUrl = getCivicPassSrcUrl(state, gatewayStatus);
            logDebug('civicPass_check_status_complete', {
                gatewayStatus: GatewayStatus[gatewayStatus],
                iframeSrcUrl,
                payload,
                dataCollectionStatus,
            });
            return Object.assign(Object.assign({}, state), { flowId,
                gatewayStatus,
                iframeSrcUrl,
                dataCollectionStatus });
        }
        case 'userInteraction_check_gatewayToken_status': {
            const useGatewayToken = action.token || state.gatewayToken;
            // if there was an error getting the on-chain or gatekeeper status, then we need to
            // try again
            const gatewayStatus = hasFetchError(state)
                ? gatewayStatusIfAllowed(GatewayStatus.CHECKING, state)
                : statusFromToken(state, useGatewayToken);
            const useFlowId = getFlowId(action.type, Object.assign(Object.assign({}, state), { gatewayStatus }));
            const iframeSrcUrl = state.civicPass.status === CivicPassIssuanceStatus.REQUESTED || state.refreshTokenState
                ? state.iframeSrcUrl
                : getCivicPassSrcUrl(Object.assign(Object.assign({}, state), { flowId: useFlowId }), gatewayStatus);
            logDebug(`userInteraction_check_gatewayToken_status`, {
                civicPassStatus: state.civicPass.status,
                refreshTokenState: state.refreshTokenState && RefreshTokenState[state.refreshTokenState],
                gatewayStatus: gatewayStatus && GatewayStatus[gatewayStatus],
                stateGatewayToken: state.gatewayToken,
                useGatewayToken,
                iframeSrcUrl,
            });
            return Object.assign(Object.assign({}, state), { flowId: useFlowId, userInitiatedFlow: true, iframeMinimized: false, renderIframe: true, iframeSrcUrl });
        }
        case 'civicPass_close': {
            return Object.assign(Object.assign({}, state), { flowId, iframeMinimized: true });
        }
        case 'civicPass_in_progress': {
            return Object.assign(Object.assign({}, state), { flowId, gatewayStatus: gatewayStatusIfAllowed(GatewayStatus.COLLECTING_USER_INFORMATION, state), renderIframe: true, iframeMinimized: false, civicPass: Object.assign(Object.assign({}, state.civicPass), { status: CivicPassIssuanceStatus.REQUESTED }), iframeSrcUrl: getCivicPassSrcUrl(state, GatewayStatus.COLLECTING_USER_INFORMATION) });
        }
        case 'civicPass_issuance_success': {
            const { ownerSigns } = state;
            const { payload, requiresProofOfWalletOwnership } = action.payload;
            const shouldRequestPowo = ownerSigns ? false : requiresProofOfWalletOwnership;
            // Show POWO or in review for non ownerSigns depending on resulting flag
            const gatekeeperSignsStatus = shouldRequestPowo
                ? GatewayStatus.PROOF_OF_WALLET_OWNERSHIP
                : GatewayStatus.IN_REVIEW;
            // Show confirm owner transaction screen if ownerSigns is true
            const gatewayStatus = ownerSigns ? ExtendedGatewayStatus.CONFIRM_OWNER_TRANSACTION : gatekeeperSignsStatus;
            return Object.assign(Object.assign({}, state), { flowId,
                gatewayStatus, ownerTransactionConfirmed: false, renderIframe: true, iframeMinimized: false, powoRequested: 'solana', iframeSrcUrl: getCivicPassSrcUrl(Object.assign(Object.assign({}, state), { flowId }), gatewayStatus), civicPass: Object.assign(Object.assign({}, state.civicPass), { status: CivicPassIssuanceStatus.VERIFIED, responsePayload: {
                        [CivicPassMessageAction.ISSUANCE]: {
                            payload,
                            requiresProofOfWalletOwnership: shouldRequestPowo,
                        },
                    } }) });
        }
        case 'civicPass_issuance_failure':
            return Object.assign(Object.assign({}, resetState(state)), { flowId, iframeMinimized: false, renderIframe: true, gatewayStatus: GatewayStatus.ERROR, iframeSrcUrl: getCivicPassSrcUrl(Object.assign(Object.assign({}, state), { flowId }), GatewayStatus.ERROR), civicPass: Object.assign(Object.assign({}, state.civicPass), { responsePayload: undefined }) });
        case 'civicPass_issuance_rejected':
            return Object.assign(Object.assign({}, state), { civicPass: Object.assign(Object.assign(Object.assign({}, state.civicPass), { status: CivicPassIssuanceStatus.FAILED }), (action.payload ? { requestPayload: action.payload } : {})), flowId, iframeMinimized: !autoShowIframe(state), renderIframe: true, gatewayStatus: GatewayStatus.REJECTED, iframeSrcUrl: getCivicPassSrcUrl(Object.assign(Object.assign({}, state), { flowId }), GatewayStatus.REJECTED, action.payload) });
        case 'civicPass_requested_retries_exhausted':
            return Object.assign(Object.assign({}, resetState(state)), { flowId, refreshTokenState: RefreshTokenState.NOT_REQUIRED, iframeMinimized: !autoShowIframe(state), renderIframe: true, gatewayStatus: GatewayStatus.ERROR, iframeSrcUrl: getCivicPassSrcUrl(state, GatewayStatus.ERROR) });
        case 'civicPass_error_user_retry': {
            // retry either issuance or refresh depending on the refresh token state
            const gatewayStatus = gatewayStatusIfAllowed(state.refreshTokenState === RefreshTokenState.FAILED
                ? GatewayStatus.REFRESH_TOKEN_REQUIRED
                : GatewayStatus.NOT_REQUESTED, state);
            logDebug('civicPass_error_user_retry', { gatewayStatus: gatewayStatus && GatewayStatus[gatewayStatus] });
            return Object.assign(Object.assign(Object.assign({}, (gatewayStatus === GatewayStatus.NOT_REQUESTED
                ? Object.assign(Object.assign({}, state), { tokenIssuanceState: TokenIssuanceState.NOT_REQUESTED, gatewayStatus }) : state)), (hasFetchError(state) ? { userInitiatedFlow: false } : {})), { // reset so that the user can try again
                flowId, iframeMinimized: false, renderIframe: true, gatewayStatus, iframeSrcUrl: getCivicPassSrcUrl(state, gatewayStatus), walletPowoInProgress: false, ownerTransactionConfirmed: false });
        }
        case 'civicPass_issuance_cancelled':
            return Object.assign(Object.assign({}, resetState(state)), { flowId, gatewayStatus: gatewayStatusIfAllowed(GatewayStatus.NOT_REQUESTED, state) });
        case 'civicPass_refresh_success': {
            const { ownerSigns } = state;
            const { payload, requiresProofOfWalletOwnership } = action.payload;
            const shouldRequestPowo = ownerSigns ? false : requiresProofOfWalletOwnership;
            const gatewayStatus = shouldRequestPowo
                ? GatewayStatus.PROOF_OF_WALLET_OWNERSHIP
                : ExtendedGatewayStatus.TOKEN_REFRESH_IN_REVIEW;
            return Object.assign(Object.assign({}, state), { flowId,
                gatewayStatus, renderIframe: true, iframeMinimized: !state.userInitiatedFlow, iframeSrcUrl: getCivicPassSrcUrl(Object.assign(Object.assign({}, state), { flowId }), gatewayStatus), refreshTokenState: RefreshTokenState.IN_PROGRESS, civicPass: Object.assign(Object.assign({}, state.civicPass), { responsePayload: {
                        [CivicPassMessageAction.REFRESH]: {
                            payload,
                            requiresProofOfWalletOwnership: shouldRequestPowo,
                        },
                    } }) });
        }
        case 'civicPass_refresh_cancelled':
            return Object.assign(Object.assign({}, state), { flowId, refreshTokenState: RefreshTokenState.CANCELLED, gatewayStatus: GatewayStatus.REFRESH_TOKEN_REQUIRED, iframeSrcUrl: getCivicPassSrcUrl(Object.assign(Object.assign({}, state), { flowId }), GatewayStatus.REFRESH_TOKEN_REQUIRED) });
        case 'civicPass_refresh_failure':
            return Object.assign(Object.assign({}, state), { flowId, refreshTokenState: RefreshTokenState.FAILED, gatewayStatus: GatewayStatus.ERROR, iframeMinimized: false, renderIframe: true, iframeSrcUrl: getCivicPassSrcUrl(Object.assign(Object.assign({}, state), { flowId }), GatewayStatus.ERROR), civicPass: Object.assign(Object.assign({}, state.civicPass), { responsePayload: undefined }) });
        case 'civicPass_location_not_supported':
            return Object.assign(Object.assign({}, state), { flowId, iframeMinimized: true, gatewayStatus: state.gatewayStatus, iframeSrcUrl: getCivicPassSrcUrl(state, state.gatewayStatus), refreshTokenState: state.gatekeeperRecordState === GatekeeperAPIStatus.ISSUED_LOCATION_NOT_SUPPORTED
                    ? RefreshTokenState.REQUIRES_POWO
                    : state.refreshTokenState });
        case 'civicPass_vpn_not_supported':
            return Object.assign(Object.assign({}, state), { flowId, iframeMinimized: true, gatewayStatus: state.gatewayStatus, iframeSrcUrl: getCivicPassSrcUrl(state, state.gatewayStatus) });
        case 'civicPass_owner_transaction_confirmed':
            return Object.assign(Object.assign({}, state), { flowId, ownerTransactionConfirmed: true, iframeSrcUrl: getCivicPassSrcUrl(state, state.gatewayStatus) });
        /** CPASS-1271 These actions are initiated by the RC and are not initiated by the Iframe, they should be moved */
        case 'civicPass_awaiting_owner_transaction':
            return Object.assign(Object.assign({}, state), { flowId, iframeMinimized: !state.userInitiatedFlow, renderIframe: true, gatewayStatus: ExtendedGatewayStatus.AWAITING_OWNER_TRANSACTION, iframeSrcUrl: getCivicPassSrcUrl(state, ExtendedGatewayStatus.AWAITING_OWNER_TRANSACTION) });
        case 'civicPass_owner_transaction_timeout':
            return Object.assign(Object.assign({}, state), { flowId, iframeMinimized: false, renderIframe: true, gatewayStatus: ExtendedGatewayStatus.CHAIN_TIMEOUT_ERROR, iframeSrcUrl: getCivicPassSrcUrl(state, ExtendedGatewayStatus.CHAIN_TIMEOUT_ERROR) });
        default:
            return state;
    }
};
const useCivicPass = ({ wallet }, state, dispatch) => {
    // const { gatekeeperRecordState } = state;
    const getActionForIssuanceResponse = (response) => {
        const actions = {
            [CivicPassMessageEventResult.SUCCESS]: {
                type: 'civicPass_issuance_success',
                payload: response,
            },
            [CivicPassMessageEventResult.FAILURE]: {
                type: 'civicPass_issuance_failure',
            },
            [CivicPassMessageEventResult.CANCELLED]: {
                type: 'civicPass_issuance_cancelled',
            },
            [CivicPassMessageEventResult.IN_PROGRESS]: {
                type: 'civicPass_in_progress',
            },
        };
        return actions[response.event];
    };
    const getActionForRefreshResponse = (response) => {
        const actions = {
            [CivicPassMessageEventResult.SUCCESS]: {
                type: 'civicPass_refresh_success',
                payload: response,
            },
            [CivicPassMessageEventResult.CANCELLED]: {
                type: 'civicPass_refresh_cancelled',
            },
            [CivicPassMessageEventResult.FAILURE]: {
                type: 'civicPass_refresh_failure',
            },
        };
        return actions[response.event];
    };
    const getActionForPowoResponse = (response) => {
        const actions = {
            [CivicPassMessageEventResult.SUCCESS]: {
                type: 'powoComplete',
            },
        };
        return actions[response.event];
    };
    const getActionForLocationNotSupportedResponse = (response) => {
        const actions = {
            [CivicPassMessageEventResult.SUCCESS]: {
                type: 'civicPass_location_not_supported',
            },
        };
        return actions[response.event];
    };
    const getActionForVpnNotSupportedResponse = (response) => {
        const actions = {
            [CivicPassMessageEventResult.SUCCESS]: {
                type: 'civicPass_vpn_not_supported',
            },
        };
        return actions[response.event];
    };
    const getActionForStaticResponse = (response) => {
        const actions = {
            [CivicPassMessageEventResult.SUCCESS]: {
                type: 'civicPass_close',
            },
            [CivicPassMessageEventResult.FAILURE]: {
                type: 'civicPass_error_user_retry',
            },
        };
        const returnAction = actions[response.event];
        return returnAction;
    };
    const getActionForStatusResponse = (response) => {
        const actions = {
            [CivicPassMessageEventResult.SUCCESS]: {
                type: 'civicPass_check_status_complete',
                payload: response,
            },
        };
        return actions[response.event];
    };
    const getActionForSignTransactionResponse = (response) => {
        const actions = {
            [CivicPassMessageEventResult.SUCCESS]: {
                type: 'civicPass_owner_transaction_confirmed',
            },
        };
        return actions[response.event];
    };
    const dispatchComplianceEventResult = (response) => {
        const actions = {
            [CivicPassMessageAction.ISSUANCE]: getActionForIssuanceResponse(response),
            [CivicPassMessageAction.PROOF_OF_WALLET_OWNERSHIP]: getActionForPowoResponse(response),
            [CivicPassMessageAction.TOKEN_IN_REVIEW]: getActionForStaticResponse(response),
            [CivicPassMessageAction.TOKEN_REFRESH_IN_REVIEW]: getActionForStaticResponse(response),
            [CivicPassMessageAction.TOKEN_ACTIVE]: getActionForStaticResponse(response),
            [CivicPassMessageAction.TOKEN_FROZEN]: getActionForStaticResponse(response),
            [CivicPassMessageAction.TOKEN_REJECTED]: getActionForStaticResponse(response),
            [CivicPassMessageAction.TOKEN_REVOKED]: getActionForStaticResponse(response),
            [CivicPassMessageAction.FAILED_IP_CHECK]: getActionForLocationNotSupportedResponse(response),
            [CivicPassMessageAction.FAILED_VPN_CHECK]: getActionForVpnNotSupportedResponse(response),
            [CivicPassMessageAction.REFRESH]: getActionForRefreshResponse(response),
            [CivicPassMessageAction.STATUS]: getActionForStatusResponse(response),
            [CivicPassMessageAction.ERROR]: getActionForStaticResponse(response),
            [CivicPassMessageAction.CHAIN_ERROR]: getActionForStaticResponse(response),
            [CivicPassMessageAction.SIGN_TRANSACTION]: getActionForSignTransactionResponse(response),
        };
        const action = actions[response.action];
        if (action) {
            logDebug('Successfully processed compliance event with action', action);
            dispatch(action);
        }
    };
    /**
     * Listen for post messages from the compliance iframe and dispatch events
     * based on the event type
     */
    useEffect(() => {
        const handler = async (response) => {
            var _a, _b;
            if (!state.iframeSrcUrl) {
                return;
            }
            const iframeOrigin = getOriginWithoutPort(`${state.iframeSrcUrl}`);
            const responseOrigin = getOriginWithoutPort(response.origin);
            if (responseOrigin !== iframeOrigin) {
                return;
            }
            // If there are multiple instances of the component on the page, we only want to respond to messages using our own instanceId
            if (((_a = response.data) === null || _a === void 0 ? void 0 : _a.instanceId) !== state.instanceId) {
                if ((_b = response.data) === null || _b === void 0 ? void 0 : _b.instanceId) {
                    logDebug('useEffect response.data?.id !== state.instanceId', { response, state });
                }
                return;
            }
            logDebug('useEffect received postMessage', response.data);
            dispatchComplianceEventResult(response.data);
        };
        logDebug('Adding event listener for iframe events');
        window.addEventListener('message', handler);
        return () => {
            logDebug('Removing event listener for iframe events');
            return window.removeEventListener('message', handler);
        };
    }, [wallet === null || wallet === void 0 ? void 0 : wallet.publicKey, state.instanceId, state.iframeSrcUrl]);
    return { dispatchComplianceEventResult };
};
export default useCivicPass;
