import { create } from '@identity.com/prove-ethereum-wallet';
import { Chain, EthereumWalletAdapter, SignatureMethod, Wallet } from '../types';
import { windowEventListener, EventListener } from './eventListener';
import { RemoteWallet } from './remote';
const TIMEOUT = 60 * 1000;

/**
 *
 * @param wallet the ethereum wallet adapter
 * @returns {Wallet} a wallet object that can be used to sign a proof
 */
export const LocalEthereumWallet = (wallet: EthereumWalletAdapter): Wallet => ({
  getPublicKey: () => Promise.resolve(wallet.address),
  getDid: () => Promise.resolve(`did:pkh:eip155:1:${wallet.address}`),
  signProof: async (message: string) => {
    const { verifierAddress } = wallet;
    const payload = message ? { message } : { verifierAddress };
    const proof = await create(wallet.signTypedData, payload);
    return {
      proof,
      chain: Chain.ETHEREUM,
      signatureMethod: SignatureMethod.MESSAGE,
      options: { verifierAddress },
    };
  },
  signMessage: async () => {
    throw new Error(`Not implemented`);
  },
  chain: Chain.ETHEREUM,
});

/**
 *
 * @param timeout timeout in milliseconds waiting for the remote wallet to receive a response
 * @param eventListener the event listener to use for the remote wallet
 * @returns {Wallet}
 */
export const RemoteEthereumWallet = ({
  timeout = TIMEOUT,
  eventListener = windowEventListener(),
  instanceId,
  debug,
}: {
  timeout: number;
  eventListener?: EventListener;
  instanceId: string;
  debug?: boolean;
}): Wallet => {
  const remoteWallet = RemoteWallet({
    chain: Chain.ETHEREUM,
    eventListener,
    timeout,
    instanceId,
    debug,
  });

  return remoteWallet;
};
