import { PublicKey, Transaction } from '@solana/web3.js';
import { TypedDataDomain, TypedDataField } from '@ethersproject/abstract-signer';
import { EthPowoMessage } from '@identity.com/prove-ethereum-wallet/dist/types';
import { Config } from '@identity.com/prove-solana-wallet';
import { CLPublicKey } from 'casper-js-sdk';

export interface Wallet {
  getPublicKey: () => Promise<string>;
  getDid: () => Promise<string>;
  signProof: (message: string) => Promise<WalletSignedProof>;
  signMessage: (message: Uint8Array) => Promise<Uint8Array>;
  chain: Chain;
}

export interface RemoteSign {
  sendPublicKey: (publicKey: string) => void;
  sendDid: (did: string) => void;
  sendSignedProof: (proof: string) => void;
  sendSignedMessage: (message: Uint8Array) => void;
}

export interface SolanaWalletAdapter {
  publicKey: PublicKey;
  signTransaction: (transaction: Transaction) => Promise<Transaction>;
  signMessage?: (message: Uint8Array) => Promise<Uint8Array>;
}

export interface EthereumWalletAdapter {
  address: string;
  signTypedData: (
    domain: TypedDataDomain,
    types: Record<string, TypedDataField[]>,
    value: EthPowoMessage
  ) => Promise<string>;
  verifierAddress: string;
}

export interface CasperWalletAdapter {
  publicKey: CLPublicKey;
  signMessage: (message: string) => Promise<Uint8Array>;
}

export type AccountInfo = {
  did: string;
  publicKey: string;
};

export enum SignatureMethod {
  TRANSACTION,
  MESSAGE,
}

export enum Chain {
  SOLANA,
  ETHEREUM,
  CASPER,
}

export type WalletSignedProof = {
  proof: string;
  signatureMethod: SignatureMethod;
  chain: Chain;
  options?: EthereumSignedProofOptions | SolanaSignedProofOptions | CasperSignedProofOptions;
};

export type SignedProof = WalletSignedProof & {
  libraryVersion: string;
};

export interface CivicSignProve {
  requestDid: () => Promise<AccountInfo>;
  requestProof: (message: string) => Promise<SignedProof>;
  signMessage: (message: Uint8Array) => Promise<Uint8Array>;
}

export interface CivicSignVerify {
  verify: (did: string, signedProof: SignedProof, message: string) => Promise<void>;
}

export enum EventTypeRequest {
  REQUEST_PUBLIC_KEY = 'REQUEST_PUBLIC_KEY',
  REQUEST_DID = 'REQUEST_DID',
  REQUEST_SIGNED_PROOF = 'REQUEST_SIGNED_PROOF',
  REQUEST_SIGNED_MESSAGE = 'REQUEST_SIGNED_MESSAGE',
}

export type EventTypeRequestSignedProofData = Uint8Array | string;

export type EventTypeRequestWithPayload = {
  request: EventTypeRequest;
  payload?: EventTypeRequestSignedProofData;
};

export type EventTypeRequestWithPayloadPostMessage = EventTypeRequestWithPayload & {
  instanceId?: string;
};

export enum EventTypeResponse {
  RESPONSE_PUBLIC_KEY = 'RESPONSE_PUBLIC_KEY',
  RESPONSE_DID = 'RESPONSE_DID',
  RESPONSE_SIGNED_PROOF = 'RESPONSE_SIGNED_PROOF',
  RESPONSE_SIGNED_MESSAGE = 'RESPONSE_SIGNED_MESSAGE',
}

export enum VerificationMethodType {
  Ed25519VerificationKey2018 = 'Ed25519VerificationKey2018',
  EcdsaSecp256k1RecoveryMethod2020 = 'EcdsaSecp256k1RecoveryMethod2020',
}

export enum DIDResolverUrl {
  CIVIC = 'https://did.civic.com/1.0/identifiers',
}

export type EthereumSignedProofOptions = { verifierAddress: string };
export type CasperSignedProofOptions = Record<string, never>; // For casper we always sign a string (i.e. nonce from civic-pass-api), no verifierAddress needed.
export type SolanaSignedProofOptions = Config;
