import { createContext, useEffect, useState } from 'react';
import axios from 'axios';

export type GatekeeperNetwork = {
  id: string;
  alias: string | undefined;
  description: string | undefined;
  partner: string | undefined;
  partnerName: string | undefined;
  chains: Record<string, unknown>;
};

type GatekeeperNetworkServiceInputProps = {
  children: JSX.Element;
};

type GatekeeperNetworkServiceOutputProps = {
  gatekeeperNetworks: GatekeeperNetwork[];
  gknsFetchComplete: boolean;
  stage: string | undefined;
  gknsPartnerId: string;
  setStage: (_stage: string) => void;
  setDefaultStage: (_stage: string) => void;
  setGknsPartnerId: (partnerId: string) => void;
};

export const GatekeeperNetworkServiceContext = createContext<GatekeeperNetworkServiceOutputProps>(
  {} as GatekeeperNetworkServiceOutputProps
);

export const GKNS_ENDPOINTS = {
  prod: 'https://api.civic.com/gkn',
  preprod: 'https://dev.api.civic.com/gkn-preprod',
  default: 'https://dev.api.civic.com/gkn-dev',
};

const CIVIC_OPEN_PARTNER_IDS = {
  default: 'org_fO1KW30kYw78qe4O',
  preprod: 'org_fO1KW30kYw78qe4O',
  prod: 'org_Mn6vwIFyFCUtg10R',
};

/**
 * Provider that fetches the list of public Gatekeeper Networks
 * from the Gatekeeper Network Service.
 * @param props
 * @constructor
 */
export function GatekeeperNetworkServiceProvider(props: GatekeeperNetworkServiceInputProps): JSX.Element {
  const [gatekeeperNetworks, setGatekeeperNetworks] = useState<GatekeeperNetwork[]>([]);
  const [gknsFetchComplete, setGknsFetchComplete] = useState<boolean>(false);
  const [defaultStage, setDefaultStage] = useState<string>('dev');
  const [stage, setStage] = useState<string>(defaultStage);
  const [gknsPartnerId, setGknsPartnerId] = useState<string>(CIVIC_OPEN_PARTNER_IDS['default']);

  const { children } = props;

  const fetchPublicGatekeeperNetworks = async () => {
    const endpoint = GKNS_ENDPOINTS[stage || 'default'] || GKNS_ENDPOINTS['default'];
    const gknUrl = `${endpoint}/public/gatekeeperNetwork?partner=${gknsPartnerId}`;
    return axios.get(gknUrl);
  };

  useEffect(() => {
    fetchPublicGatekeeperNetworks().then((result) => {
      setGatekeeperNetworks(result.data.records);
      setGknsFetchComplete(true);
    });
  }, [stage, gknsPartnerId]);

  useEffect(() => {
    setGatekeeperNetworks([]);
    setGknsFetchComplete(false);
    setGknsPartnerId(CIVIC_OPEN_PARTNER_IDS[stage] || CIVIC_OPEN_PARTNER_IDS['default']);
  }, [stage]);

  useEffect(() => {
    // When the page loads, the initial stage will be set in query params.
    // App.tsc will call setDefaultStage.
    // We set the 'selected' stage to the initial stage here.
    setStage(defaultStage);
  }, [defaultStage]);

  return (
    <GatekeeperNetworkServiceContext.Provider
      value={{
        gatekeeperNetworks,
        gknsFetchComplete,
        setStage,
        setDefaultStage,
        setGknsPartnerId,
        stage,
        gknsPartnerId,
      }}
    >
      {children}
    </GatekeeperNetworkServiceContext.Provider>
  );
}
