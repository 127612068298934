export interface LoggingInterface {
  debug: (message: string, object?: unknown) => void;
  info: (message: string, object?: unknown) => void;
  warn: (message: string, object?: unknown) => void;
  error: (message: string, exception?: unknown) => void;
}

const logLevels = ['debug', 'info', 'warn', 'error'];

export const allowLogging = (logLevel: string, atLeastlogLevel: string): boolean =>
  logLevels.indexOf(logLevel) <= logLevels.indexOf(atLeastlogLevel);

export const DEFAULT_LOG_LEVEL = 'warn';

export class LoggingProvider implements LoggingInterface {
  logger: LoggingInterface;

  logLevel: string;

  constructor(logger: LoggingInterface, logLevel = DEFAULT_LOG_LEVEL) {
    this.logger = logger;
    if (typeof window !== 'undefined') {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const urllogLevel = urlSearchParams.get('logLevel');
      this.logLevel = urllogLevel || logLevel;
    } else {
      this.logLevel = logLevel;
    }
  }

  debug(message: string, object?: unknown): void {
    if (allowLogging(this.logLevel, 'debug')) this.logger.debug(message, object);
  }

  info(message: string, object?: unknown): void {
    if (allowLogging(this.logLevel, 'info')) this.logger.info(message, object);
  }

  warn(message: string, object?: unknown): void {
    if (allowLogging(this.logLevel, 'warn')) this.logger.warn(message, object);
  }

  error(message: string, exception?: unknown): void {
    if (allowLogging(this.logLevel, 'error')) this.logger.error(message, exception);
  }
}
