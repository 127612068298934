import { SolanaNetwork } from '@civic/gatekeeper-types';

export const TESTID_IFRAME = 'IFRAME';
export const TESTID_WRAPPER = 'WRAPPER';
export const TESTID_WRAPPER_CONTAINER = 'WRAPPER_CONTAINER';
export const TESTID_RECONNECT_WALLET = 'RECONNECT_WALLET';
export const TESTID_IFRAME_CLOSE_BTN = 'TESTID_IFRAME_CLOSE_BTN';

export const SOLANA_DEVNET_ENDPOINT = 'https://api.devnet.solana.com';
export const SOLANA_MAINNET_ENDPOINT = 'https://api.mainnet-beta.solana.com';
export const DEFAULT_CIVIC_COMPLIANCE_SRC_URL = 'https://pass-dev.civic.com/';

export enum EndpointType {
  PUBLIC = 'v1/token',
  TENANT = 'v1/tenant/token',
  ADMIN = 'v1/admin/token',
}

export const GATEKEEPER_ENDPOINTS: { [key: string]: string } = {
  local: 'http://localhost:3001/local',
  test: 'https://dev-gatekeeper-api.civic.com', // stage for automated tests
  dev: 'https://dev-gatekeeper-api.civic.com',
  preprod: 'https://preprod-gatekeeper-api.civic.com',
  prod: 'https://gatekeeper-api.civic.com',
};

export const GATEKEEPER_ADMIN_ENDPOINTS: { [key: string]: string } = {
  local: 'http://localhost:3001/local',
  test: 'https://dev.api.civic.com/ociv-gatekeeper-dev', // stage for automated tests
  dev: 'https://dev.api.civic.com/ociv-gatekeeper-dev',
  preprod: 'https://dev.api.civic.com/ociv-gatekeeper-preprod',
  prod: 'https://api.civic.com/ociv-gatekeeper',
};

export const COMPLIANCE_ENDPOINTS: { [key: string]: string } = {
  local: 'http://localhost:3004',
  test: 'http://localhost:3004', // stage for automated tests
  dev: 'https://passv2-dev.civic.com',
  preprod: 'https://passv2-preprod.civic.com',
  prod: 'https://passv2.civic.com',
};

export const getGatekeeperEndpointNoSuffix = (stage: string, endpoints = GATEKEEPER_ENDPOINTS): string => {
  const endpoint = endpoints[stage];
  if (!endpoint) {
    throw new Error(`Invalid stage ${stage}`);
  }
  return endpoint;
};
export const getGatekeeperEndpoint = (
  stage: string,
  endpointType: EndpointType = EndpointType.PUBLIC,
  chain = 'solana'
): string => {
  // admin base endpoints are different as they do not (currently) use the cloudfront endpoints
  const endpoints = endpointType === EndpointType.ADMIN ? GATEKEEPER_ADMIN_ENDPOINTS : GATEKEEPER_ENDPOINTS;
  return `${getGatekeeperEndpointNoSuffix(stage, endpoints)}/${endpointType.toString()}/${chain}`;
};

export const getComplianceEndpoint = (stage: string): string => {
  const endpoint = COMPLIANCE_ENDPOINTS[stage];
  if (!endpoint) {
    throw new Error(`Invalid stage ${stage}`);
  }
  return endpoint;
};

export const DEFAULT_NETWORK: SolanaNetwork = (process.env.DEFAULT_NETWORK as SolanaNetwork) || 'mainnet-beta';
