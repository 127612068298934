/* eslint-disable no-console */
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import { GatewayStatus } from '@civic/common-gateway-react';
import { VERSION } from './version';
export { GatewayStatus };
export { IdentityButton, getTokenDescription, ButtonMode } from '@civic/common-gateway-react';
export { useEthereumGateway as useGateway } from './chainImplementation';
export { EthereumGatewayProvider as GatewayProvider } from './EthereumGatewayProvider';
console.log(VERSION);
