import { EventTypeRequest } from '../types';
import { prefixLogger } from './logger';
import { EventTypeResponse } from '../types';
import { EventEmitter } from './eventListener';
import { RemoteSign } from '../types';

const logDebug = prefixLogger('RemoteSign').debug;

export const eventEmitter = (targetWindow: Window): EventEmitter => {
  return {
    postMessage: (message: unknown, targetOrigin: string) => {
      targetWindow.postMessage(message, targetOrigin);
    },
  };
};

export type EventTypeRequestMessage = {
  request: EventTypeRequest;
  payload?: Uint8Array | string;
};

export type ResponseEvent = {
  event: EventTypeResponse;
  data: string;
  instanceId: string;
};

export class RemoteSignWindowEventEmitter implements RemoteSign {
  private targetWindow: EventEmitter;

  constructor(targetWindow: EventEmitter, readonly instanceId: string) {
    this.targetWindow = targetWindow;
  }

  public sendPublicKey(publicKey: string) {
    this.emit({
      event: EventTypeResponse.RESPONSE_PUBLIC_KEY,
      data: publicKey,
    });
  }

  public sendDid(did: string) {
    this.emit({
      event: EventTypeResponse.RESPONSE_DID,
      data: did,
    });
  }

  public sendSignedProof(proof: string) {
    this.emit({
      event: EventTypeResponse.RESPONSE_SIGNED_PROOF,
      data: { proof, signatureMethod: 1 },
    });
  }

  public sendSignedMessage(message: Uint8Array) {
    this.emit({
      event: EventTypeResponse.RESPONSE_SIGNED_MESSAGE,
      data: message,
    });
  }

  private emit(event: { event: EventTypeResponse; data: unknown }) {
    this.targetWindow.postMessage({ ...event, instanceId: this.instanceId }, '*');
  }
}

export const remoteSignWindowEventEmitterImplementation = (instanceId: string, baseIframeId: string) => {
  const iframeDOMId = `${baseIframeId}_${instanceId}`;
  const iFrameWindow = (document.getElementById(iframeDOMId) as HTMLIFrameElement)?.contentWindow;
  logDebug('remoteSignWindowEventEmitterImplementation', {
    iFrameWindow,
    iframeDOMId,
    instanceId,
  });
  if (!iFrameWindow) {
    logDebug(`remoteSignWindowEventEmitterImplementation: no iFrameWindow window found with instanceId: ${instanceId}`);
    return null;
  }
  return new RemoteSignWindowEventEmitter(eventEmitter(iFrameWindow), instanceId);
};
