import {
  EventTypeResponse,
  EventTypeRequestWithPayload,
  EventTypeRequest,
  EventTypeRequestWithPayloadPostMessage,
} from '../types';
export interface EventListener {
  postMessageWithPayload: (event: EventTypeRequestWithPayloadPostMessage, origin: string) => void;
  postMessage: (event: EventTypeRequest, origin: string) => void;
  addEventListener: (callback: (event: ResponseEvent) => void) => void;
}

export interface EventEmitter {
  postMessage: (message: unknown, targetOrigin: string) => void;
}

export type ResponseEvent = {
  event: EventTypeResponse;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  instanceId?: string;
};

export const windowEventListener = (targetWindow: Window = window): EventListener => {
  return {
    postMessageWithPayload: (event: EventTypeRequestWithPayload, origin: string) => {
      targetWindow.parent.postMessage(event, origin);
    },

    postMessage: (event: EventTypeRequest, origin: string) => {
      targetWindow.parent.postMessage(event, origin);
    },

    addEventListener: (callback: (event: ResponseEvent) => void) => {
      targetWindow.addEventListener('message', (event) => {
        return callback(event.data as unknown as ResponseEvent);
      });
    },
  };
};
