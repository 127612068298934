import { verifyMessageSignature, CLPublicKey } from 'casper-js-sdk';
import { SignedProof } from '../../types';
import { ProofVerificationStrategy } from '.';
import logger from '../logger';

/**
 * Returns whether the given keyToCheck was used to sign the given signedProof.
 * The key must be base64 encoded and can be either Ed25519 or Secp256k1.
 *
 * @param signedProof The proof to evaluate, of the format '<base64 encoded message>.<base64 encoded signature>'
 * @param keyToCheckHex Hex-encoded key (both Ed25519 and Secp256k1 are accepted)
 * @param expectedMessage The message we expect to appear in the signed proof. This is usually a nonce.
 * @returns Boolean, whether the given key was a signer of the proof.
 */
export const verifyCasperProof: ProofVerificationStrategy = async (
  signedProof: SignedProof,
  keyToCheckHex: string,
  expectedMessage?: string
): Promise<boolean> => {
  const casperKey = decodeCasperKey(keyToCheckHex);
  if (!casperKey) {
    logger.warn('Skipping Casper proof verification for non-Casper key', keyToCheckHex);
    return false;
  }

  try {
    return verifyMessageSignature(casperKey, expectedMessage, new Uint8Array(Buffer.from(signedProof.proof, 'base64')));
  } catch (err) {
    logger.warn('Could not verify casper signature for key. Skipping.', keyToCheckHex);
    return false;
  }
};

const tryEd25519 = (key: string): CLPublicKey | null => {
  try {
    return CLPublicKey.fromEd25519(new Uint8Array(Buffer.from(key, 'hex')));
  } catch (err) {
    return null;
  }
};

const trySecp256k1 = (key: string): CLPublicKey | null => {
  try {
    return CLPublicKey.fromSecp256K1(new Uint8Array(Buffer.from(key, 'hex')));
  } catch {
    return null;
  }
};

const tryHex = (key: string): CLPublicKey | null => {
  try {
    return CLPublicKey.fromHex(key);
  } catch {
    return null;
  }
};

const decodeCasperKey = (key: string): CLPublicKey | undefined => tryEd25519(key) || trySecp256k1(key) || tryHex(key);

export const casperKeyMatchesWallet = (keyHex: string, walletAddress: string) => {
  try {
    return decodeCasperKey(keyHex).toHex().toLowerCase() === walletAddress.toLowerCase();
  } catch {
    return false;
  }
};
