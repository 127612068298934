import React, { ChangeEvent, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import './App.css';
import { LogLevel } from '@civic/common-gateway-react';
import { Options } from '@civic/common-gateway-react';
import { EndpointType, getGatekeeperEndpoint } from './config';
import { Connection } from '@solana/web3.js';
import {
  TESTID_CHAIN,
  TESTID_CHAIN_NETWORK,
  TESTID_CURRENT_GATEKEEPER_NETWORK,
  TESTID_CURRENT_IFRAME_URL,
  TESTID_GATEKEEPER_NETWORK,
  TESTID_CLIENT_SENDS_CHECKBOX,
  TESTID_CLIENT_SENDS_FAKE_CHECKBOX,
  TESTID_CLIENT_SENDS_NO_DELAY,
} from './constants';
import {
  EthereumConnectionAndProvider,
  EthereumGatewayStatusView,
  EthereumWalletAndProvider,
  defaultEvmChains,
  defaultEvmTestChains,
  ethereumNetworkFromStage,
  evmNetworks,
} from './components/ethereum/useEthereum';
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  TorusWalletAdapter,
  WalletConnectWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import logger from './logger';
import { ExtendedCluster } from './util';
import {
  defaultSolanaChains,
  defaultSolanaTestChains,
  SolanaConnectionAndProvider,
  SolanaGatewayStatusView,
  solanaNetworkFromStage,
  solanaNetworks,
  SolanaWalletAndProvider,
  useSolanaConnectionParams,
} from './components/solana/useSolana';
import { MultichainWalletProvider } from '@civic/multichain-connect-react-core';
import { publicProvider } from 'wagmi/providers/public';
import { Chain as SolanaChain, SolanaWalletAdapterConfig } from '@civic/multichain-connect-react-solana-wallet-adapter';
import { Chain as EthereumChain, RainbowkitConfig } from '@civic/multichain-connect-react-rainbowkit-wallet-adapter';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { GatekeeperNetwork, GatekeeperNetworkServiceContext } from './GatekeeperNetworkServiceContext';

const urlSearchParams = new URLSearchParams(window.location.search);
const paramStage = urlSearchParams.get('stage') || 'dev';

export const CIVICNET = 'https://d3ab7dlfud2b5u.cloudfront.net';

export const DEFAULT_STAGE = paramStage || 'local';
export const DEFAULT_NETWORK = 'civicnet';

const WrappedGatewayProvider: React.FC<{
  children: JSX.Element;
  stage: string;
  gatekeeperNetworkAddress: string;
  chain: string;
  wrapperSelect: string;
  connection: Connection | null;
  setIFrameUrl?: React.Dispatch<React.SetStateAction<string | undefined>>;
  network: ExtendedCluster;
  setNetwork: React.Dispatch<React.SetStateAction<string>>;
  options: Options;
  gatekeeperSendsTransaction: boolean;
  expiryMarginSeconds?: number;
  clientSendsDelay?: number;
  clientSendsNoDelayOnRetry?: number;
  partnerAppId?: string;
}> = ({
  children,
  stage,
  gatekeeperNetworkAddress,
  chain,
  wrapperSelect,
  connection,
  network,
  setNetwork,
  options,
  gatekeeperSendsTransaction,
  expiryMarginSeconds,
  clientSendsDelay,
  clientSendsNoDelayOnRetry,
  partnerAppId,
  setIFrameUrl,
}) => {
  return chain === 'solana' ? (
    <>
      {connection && (
        <>
          <SolanaWalletAndProvider
            stage={stage}
            wrapperSelect={wrapperSelect}
            gatekeeperNetworkAddress={gatekeeperNetworkAddress}
            chain={chain}
            setIFrameUrl={setIFrameUrl}
            network={network as ExtendedCluster}
            setNetwork={setNetwork}
            expiryMarginSeconds={expiryMarginSeconds}
            connection={connection}
            clientSendsDelay={clientSendsDelay}
            clientSendsNoDelayOnRetry={clientSendsNoDelayOnRetry}
            partnerAppId={partnerAppId}
            options={options}
            gatekeeperSendsTransaction={gatekeeperSendsTransaction}
          >
            {children}
          </SolanaWalletAndProvider>
        </>
      )}
    </>
  ) : (
    <EthereumWalletAndProvider
      stage={stage}
      gatekeeperNetworkAddress={gatekeeperNetworkAddress}
      gatekeeperSendsTransaction={gatekeeperSendsTransaction}
      options={options}
    >
      {children}
    </EthereumWalletAndProvider>
  );
};

const WrappedWalletProvider: React.FC<{
  children: JSX.Element;
  chain: string;
  stage: string;
  network: ExtendedCluster;
}> = ({ children, chain, stage, network }) => {
  logger.debug('WrappedWalletProvider', {
    chain,
    stage,
    network,
    EthereumConnectionAndProvider,
  });
  const filterChain = (name: string) => (chain: EthereumChain | SolanaChain) => {
    return chain.name.toLowerCase() === name;
  };
  const [initialChain, setInitialChain] = useState<EthereumChain | undefined>(undefined);
  const [evmChains, setEvmChains] = useState<{
    chains: EthereumChain[];
    testChains: EthereumChain[];
  }>({ chains: defaultEvmChains, testChains: defaultEvmTestChains });
  const [solanaChains, setSolanaChains] = useState<{
    chains: SolanaChain[];
    testChains: SolanaChain[];
  }>({ chains: defaultSolanaChains, testChains: defaultSolanaTestChains });
  useEffect(() => {
    logger.debug('useEffect initialChain', { chain, network });
    if (!network) {
      setEvmChains({
        chains: defaultEvmChains,
        testChains: defaultEvmTestChains,
      });
      setSolanaChains({
        chains: defaultSolanaChains,
        testChains: defaultSolanaTestChains,
      });
      return;
    }

    const selectedEvmChain = [...defaultEvmChains, ...defaultEvmTestChains].filter((c) => {
      return c.name.toString().toLowerCase() === network.toLowerCase();
    })[0];
    logger.debug('initialChain selectedEvmChain', selectedEvmChain);
    if (selectedEvmChain) {
      setInitialChain(selectedEvmChain);
      setEvmChains({
        chains: defaultEvmChains,
        testChains: defaultEvmTestChains,
      });
      setSolanaChains({ chains: [], testChains: [] });
      return;
    }

    const selectedSolanaChain = [...defaultSolanaChains, ...defaultSolanaTestChains].filter(
      (c) => c.name.toLowerCase() === network.toLowerCase()
    )[0];
    logger.debug('initialChain selectedSolanaChain', selectedSolanaChain);
    if (selectedSolanaChain) {
      setSolanaChains({
        chains: defaultSolanaChains.filter(filterChain(network)),
        testChains: defaultSolanaTestChains.filter(filterChain(network)),
      });
      setEvmChains({ chains: [], testChains: [] });
      return;
    }
    logger.debug('selectedSolanaChain', selectedSolanaChain);
    setEvmChains({ chains: [], testChains: [] });
    setSolanaChains({ chains: [], testChains: [] });
  }, [network, chain]);

  const solanaAdapters = useMemo(() => {
    return [
      new PhantomWalletAdapter(), // re-adding this as Phantom is not showing on mobile browsers
      new SolflareWalletAdapter(), // TODO CPASS-1585, don't include Solflare for Android only
      new LedgerWalletAdapter(),
      new WalletConnectWalletAdapter({
        options: { projectId: process.env.REACT_APP_PORTAL_WALLET_CONNECT_PROJECT_ID || '' },
        network: solanaChains.chains.length > 0 ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet,
      }),
      new TorusWalletAdapter(),
    ];
  }, []);

  return (
    <MultichainWalletProvider>
      <RainbowkitConfig
        chains={evmChains.chains}
        testnetChains={evmChains.testChains}
        providers={[publicProvider()]}
        options={{
          appName: 'CivicPassDemoApp',
          // Rainbowkit relies on WalletConnect which now needs to obtain a projectId from WalletConnect Cloud.
          walletConnectProjectId: 'cb15f45a4b51799ebac3155e52fa5129',
        }}
        initialChain={initialChain}
      >
        <SolanaWalletAdapterConfig
          chains={solanaChains.chains}
          testnetChains={solanaChains.testChains}
          adapters={solanaAdapters}
        >
          {chain === 'solana' ? (
            <SolanaConnectionAndProvider network={network}>{children}</SolanaConnectionAndProvider>
          ) : (
            <EthereumConnectionAndProvider network={network} stage={stage}>
              {children}
            </EthereumConnectionAndProvider>
          )}
        </SolanaWalletAdapterConfig>
      </RainbowkitConfig>
    </MultichainWalletProvider>
  );
};

const stages = ['local', 'test', 'dev', 'preprod', 'prod'];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function App({ customStage }: { customStage?: string }) {
  const [wrapperSelect, setWrapperSelect] = useState<string>('default');
  const urlSearchParams = new URLSearchParams(window.location.search);
  const paramRCLogLevel = urlSearchParams.get('rcLogLevel');
  const paramStage = urlSearchParams.get('stage');
  const initialStage = customStage || paramStage || 'dev';

  const {
    gatekeeperNetworks,
    gknsFetchComplete,
    stage,
    setStage,
    setDefaultStage,
    setGknsPartnerId,
    gknsPartnerId,
    gatekeeperNetworks: partnerGatekeeperNetworks,
  } = useContext(GatekeeperNetworkServiceContext);
  useEffect(() => {
    // Tell the GatekeeperNetworkService provider which stage to use by default
    setDefaultStage(initialStage);
  }, [initialStage]);

  const networkFromStage = (inStage: string, chain: string): string | undefined => {
    // only auto-update the network for solana as we don't know which EVM chain we're testing
    if (chain === 'solana') {
      return solanaNetworkFromStage(inStage).toLowerCase();
    }
    if (chain === 'ethereum') {
      return ethereumNetworkFromStage(inStage).toLowerCase();
    }
    return;
  };
  const defaultChain = urlSearchParams.get('chain') || 'solana';
  const [logLevel, setLogLevel] = useState<LogLevel>((paramRCLogLevel || 'debug') as LogLevel);
  const [hasUserManuallySelectedNetwork, setHasUserManuallySelectedNetwork] = useState<boolean>(false);
  const [chain, setChain] = useState<string>(defaultChain);

  const networkFromUrlSearchParams = useCallback(() => {
    const useChain = chain || defaultChain;
    const networkFromUrl = `${urlSearchParams.get('chainNetwork')?.toLowerCase()}`.toLowerCase();
    if (
      (useChain === 'ethereum' && evmNetworks.includes(networkFromUrl)) ||
      (useChain === 'solana' && solanaNetworks.includes(networkFromUrl))
    ) {
      return networkFromUrl;
    }
    if (!stage) {
      return undefined;
    }
    return networkFromStage(stage, useChain);
  }, [defaultChain, chain, stage, urlSearchParams, evmNetworks, solanaNetworks]);
  const defaultChainNetwork = networkFromUrlSearchParams() || DEFAULT_NETWORK;

  const [network, setNetwork] = useState<string>(defaultChainNetwork);
  const [iframeUrl, setIFrameUrl] = useState<string>();
  const [rcOptions, setRcOptions] = useState<Options>({ autoShowModal: true });
  const [clientSends, setClientSends] = useState<boolean>(urlSearchParams.get('clientSends') === 'true' || false);
  const [clientSendsDelay, setClientSendsDelay] = useState<number>();
  const [clientSendsNoDelayOnRetry, setClientSendsNoDelayOnRetry] = useState<number>(1);
  const [expiryMarginSeconds, setExpiryMarginSeconds] = useState<number>();
  const [partnerAppId, setPartnerAppId] = useState<string>();
  const [disableErrorOverlay, setDisableErrorOverlay] = useState<boolean>(
    urlSearchParams.get('disableErrorOverlay') === 'true' || false
  );

  logger.debug('App initial parameters', {
    disableErrorOverlay,
    customStage,
    paramStage,
    initialStage,
    urlSearchParams,
    clientSends,
  });

  useEffect(() => {
    // Create a style element
    const styleElement = document.createElement('style');

    // Define the CSS rule to hide the element
    const cssRule = `
      #webpack-dev-server-client-overlay {
        display: none !important;
      }
    `;

    // Set the CSS rule as the innerHTML of the style element
    styleElement.innerHTML = cssRule;

    if (disableErrorOverlay) {
      // Append the style element to the document's head
      document.head.appendChild(styleElement);
    } else if (document.head.contains(styleElement)) {
      // Remove the style element if it exists and disableErrorOverlay is false
      document.head.removeChild(styleElement);
    }

    // Cleanup: Remove the style element when the component unmounts
    return () => {
      if (document.head.contains(styleElement)) {
        document.head.removeChild(styleElement);
      }
    };
  }, [disableErrorOverlay]);

  const getGkNetworkDataByAlias = useCallback(
    (alias: string | undefined): GatekeeperNetwork => {
      const aliasToUse = alias || partnerGatekeeperNetworks?.[0].alias;
      const network = gatekeeperNetworks.find((gkn) => gkn.alias === aliasToUse);
      if (!network) {
        throw new Error(`No Gatekeeper Network config found with alias ${aliasToUse}`);
      }
      return network;
    },
    [stage, gknsFetchComplete, JSON.stringify(gatekeeperNetworks)]
  );
  const initialGatekeeperAlias = urlSearchParams.get('gatekeeperNetwork') || partnerGatekeeperNetworks?.[0]?.alias;

  const [selectedGatekeeperNetwork, setSelectedGatekeeperNetwork] = useState<GatekeeperNetwork | null>(null);
  const [gatekeeperNetworkAlias, setGatekeeperNetworkAlias] = useState<string | undefined>(initialGatekeeperAlias);

  const { solanaEndpoint, solanaConnection } = useSolanaConnectionParams(chain, network);
  // set the gatekeeperNetwork when the stage changes
  useEffect(() => {
    if (!gatekeeperNetworks || gatekeeperNetworks.length === 0) {
      return;
    }
    const newSelectedGkn = getGkNetworkDataByAlias(gatekeeperNetworkAlias);
    if (!newSelectedGkn) throw new Error('No config found for gatekeeper network');
    setSelectedGatekeeperNetwork(newSelectedGkn);
  }, [stage, gatekeeperNetworkAlias, gknsFetchComplete, gatekeeperNetworks]);

  const supportedChainsForGkNetwork = useMemo(() => {
    if (!selectedGatekeeperNetwork) return [];
    const chains = [...Object.keys(selectedGatekeeperNetwork.chains || {})];

    let chainToUse: string | undefined = undefined;

    // Default to the first supported chain...
    if (chains?.length > 0) {
      chainToUse = chains[0];
    }

    // ... but favour the chain from query params if set (as long as it's a supported chain for this network)
    const chainFromParams = urlSearchParams.get('chain')?.toLowerCase();
    if (chainFromParams && chains?.includes(chainFromParams)) {
      chainToUse = chainFromParams;
    }

    // If a chainNetwork is given but no chain type, assume solana:
    if (!chainFromParams && urlSearchParams.get('chainNetwork')) {
      chainToUse = 'solana';
    }

    if (chainToUse) {
      setChain(chainToUse);
    }

    return chains;
  }, [stage, selectedGatekeeperNetwork]);

  const supportedNetworks = useMemo(() => {
    const chainNetworks = Object.keys(selectedGatekeeperNetwork?.chains?.[chain]?.['networks'] || {});
    if (chain === 'solana' && !chainNetworks.includes('localnet')) {
      // Don't assume that localnet is mentioned in the GKNS database.
      chainNetworks.push('localnet');
    }

    // Default to the first chain network:
    let chainNetworkToUse: string | undefined = chainNetworks?.[0];

    // but favour the one from query params if it's one of the supported networks:
    const networkFromParams = urlSearchParams.get('chainNetwork')?.toLowerCase();
    if (networkFromParams && chainNetworks.includes(networkFromParams)) {
      chainNetworkToUse = networkFromParams;
    }
    if (chainNetworkToUse) {
      setNetwork(chainNetworkToUse);
    }
    return chainNetworks;
  }, [chain, stage, selectedGatekeeperNetwork]);

  const gatekeeperNetworkChanged = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.currentTarget.value) setGatekeeperNetworkAlias(event.currentTarget.value);
    },
    [selectedGatekeeperNetwork, setGatekeeperNetworkAlias]
  );
  const networkChanged = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.currentTarget.value) setNetwork(event.currentTarget.value);
    },
    [network, setNetwork]
  );

  useEffect(() => {
    if (!hasUserManuallySelectedNetwork) {
      setNetwork(networkFromUrlSearchParams() || DEFAULT_NETWORK);
      console.log(network);
      console.log(networkFromUrlSearchParams);
    }
  }, [chain, networkFromUrlSearchParams]);

  const chainChanged = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      if (event.currentTarget.value) setChain(event.currentTarget.value);
    },
    [setChain]
  );

  const clientSendsChanged = useCallback(() => {
    setClientSends(!clientSends);
  }, [clientSends]);

  const onWrapperChange = useCallback((selectedOption: string) => {
    setWrapperSelect(selectedOption);
  }, []);

  const onStageChange = useCallback(
    (selectedOption: string) => {
      setStage(selectedOption);
      const useNetworkFromStage = networkFromUrlSearchParams();
      logger.debug('onStageChange', { selectedOption, useNetworkFromStage });
      if (useNetworkFromStage && !hasUserManuallySelectedNetwork) {
        setNetwork(networkFromUrlSearchParams() || DEFAULT_NETWORK);
      }
    },
    [chain, hasUserManuallySelectedNetwork]
  );
  const gatekeeperEndpoint = useMemo(() => {
    if (!stage) {
      return undefined;
    }
    return getGatekeeperEndpoint(stage, EndpointType.PUBLIC, chain);
  }, [stage, chain]);

  const onAutoShowModalChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      logger.debug('onAutoShowModalChange event.currentTarget.value', event.currentTarget.value);
      setRcOptions({ autoShowModal: !!(event.currentTarget.value === 'true') });
    },
    [setRcOptions]
  );
  logger.debug('top-level stage, gatekeeperNetworkAddress', {
    chain,
    stage,
    gatekeeperNetworkAddress: selectedGatekeeperNetwork?.id,
    network,
    iframeUrl,
  });
  const currentUrl = `${window.location.origin}?stage=${stage}&chain=${chain}&chainNetwork=${network}&gatekeeperNetwork=${gatekeeperNetworkAlias}&clientSends=${clientSends}&disableErrorOverlay=${disableErrorOverlay}`;
  return (
    <>
      {stage && (
        <WrappedWalletProvider chain={chain} stage={stage} network={network as ExtendedCluster}>
          <div className="App">
            <header className="App-header">
              <div className="options">
                <div>
                  <h4>Parameters passed to GatewayProvider:</h4>
                  <span>Stage:</span>
                  {stages.map((useStage) => {
                    return (
                      <span key={`stage_${useStage}`}>
                        <input
                          type="radio"
                          data-testid={`TESTID_STAGE_${useStage}`}
                          value={useStage}
                          name="stage"
                          checked={stage === useStage}
                          onChange={() => onStageChange(`${useStage}`)}
                        />
                        {useStage}
                      </span>
                    );
                  })}
                  <br />
                  <br />
                  <div>
                    <span>Partner ID (GKNS): </span>
                    <input
                      type="string"
                      value={gknsPartnerId}
                      onChange={(event) => setGknsPartnerId(event.currentTarget.value)}
                      size={35}
                    />
                  </div>
                  <div>
                    <span>Gatekeeper Network:&nbsp;</span>
                    <select
                      value={gatekeeperNetworkAlias}
                      data-testid={TESTID_GATEKEEPER_NETWORK}
                      onChange={(event) => gatekeeperNetworkChanged(event)}
                    >
                      {gatekeeperNetworks.map((gkn: GatekeeperNetwork) => {
                        const getGknLabel = (gkn: GatekeeperNetwork): string => {
                          let label = '';
                          if (gkn.partnerName) {
                            label += `[${gkn.partnerName}] - `;
                          }
                          label += gkn.description || gkn.alias;
                          return label;
                        };

                        return (
                          <option key={`gatekeeperNetwork_${gkn.id}`} value={gkn.alias}>
                            {getGknLabel(gkn)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <span>Chain: </span>
                    <select value={chain} data-testid={TESTID_CHAIN} onChange={(event) => chainChanged(event)}>
                      {supportedChainsForGkNetwork.map((chainName) => {
                        return (
                          <option key={`chain_${chainName}`} value={chainName}>
                            {chainName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <span>Chain Network: </span>
                    <select
                      data-testid={TESTID_CHAIN_NETWORK}
                      value={network}
                      onClick={() => setHasUserManuallySelectedNetwork(true)}
                      onChange={(event) => networkChanged(event)}
                      disabled={false}
                    >
                      {supportedNetworks.map((aliasName) => {
                        return (
                          <option key={`chainNetwork_${aliasName}`} value={aliasName}>
                            {aliasName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div>
                    <span>Client Sends Transaction: </span>
                    <input
                      data-testid={TESTID_CLIENT_SENDS_CHECKBOX}
                      type="checkbox"
                      defaultChecked={clientSends}
                      onChange={() => clientSendsChanged()}
                    />
                  </div>
                  <div className="options">
                    <span>Wrapper type:</span>
                    <input
                      type="radio"
                      value="default"
                      name="wrapper"
                      defaultChecked
                      onChange={() => onWrapperChange('default')}
                    />{' '}
                    default
                    <input type="radio" value="logo" name="wrapper" onChange={() => onWrapperChange('logo')} /> logo
                    <input type="radio" value="custom" name="wrapper" onChange={() => onWrapperChange('custom')} />{' '}
                    custom
                    <input
                      type="radio"
                      value="customAndLogo"
                      name="wrapper"
                      onChange={() => onWrapperChange('customAndLogo')}
                    />{' '}
                    custom and logo
                  </div>
                  <div className="options">
                    <div>
                      <span>RC log level: </span>
                      <select
                        data-testid={'RC_DEBUG_LEVEL'}
                        value={logLevel}
                        onChange={(event) => setLogLevel(event.currentTarget.value as LogLevel)}
                        disabled={false}
                      >
                        {['debug', 'info', 'warn', 'error'].map((logLevel) => {
                          return (
                            <option key={`logLevel_${logLevel}`} value={logLevel}>
                              {logLevel}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <span>Autoshow CivicPass modal:</span>
                    <input
                      type="radio"
                      value="on"
                      name="wrapper"
                      defaultChecked
                      onChange={onAutoShowModalChange}
                      data-testid={`TESTID_SHOWMODAL_ON`}
                    />
                    On
                    <input
                      type="radio"
                      value="off"
                      name="wrapper"
                      onChange={onAutoShowModalChange}
                      data-testid={`TESTID_SHOWMODAL_OFF`}
                    />
                    Off
                  </div>
                  <div>
                    <span>SIP partnerAppId: </span>
                    <input
                      data-testid={'TESTID_APP_ID'}
                      type="string"
                      onChange={(event) => setPartnerAppId(event.currentTarget.value)}
                    />
                  </div>
                </div>
                <br />
                <div>
                  <h4>Advanced test parameters (only set these if you know what you're doing)</h4>
                  <div>
                    <span>Client delay sending Transaction (seconds): </span>
                    <input
                      data-testid={TESTID_CLIENT_SENDS_FAKE_CHECKBOX}
                      type="number"
                      onInput={(event) => setClientSendsDelay(event.currentTarget.valueAsNumber)}
                    />
                  </div>
                  <div>
                    <span>No delay on retry count: </span>
                    <input
                      data-testid={TESTID_CLIENT_SENDS_NO_DELAY}
                      type="number"
                      value={clientSendsNoDelayOnRetry}
                      onInput={(event) => setClientSendsNoDelayOnRetry(event.currentTarget.valueAsNumber)}
                    />
                  </div>
                  <div>
                    <span>Expiry Margin (seconds): </span>
                    <input
                      data-testid={'TESTID_EXPIRY_MARGIN_SECONDS'}
                      type="number"
                      onChange={(event) => setExpiryMarginSeconds(event.currentTarget.valueAsNumber)}
                    />
                  </div>
                  <div className="options">
                    <span>Disable React Error Overlay:</span>
                    <input
                      type="radio"
                      value="on"
                      name="wrapper"
                      {...(disableErrorOverlay ? { defaultChecked: true } : {})}
                      onChange={() => setDisableErrorOverlay(true)}
                      data-testid={`TESTID_DISABLE_REACT_ERROR_OVERLAY_ON`}
                    />
                    On
                    <input
                      type="radio"
                      value="off"
                      name="wrapper"
                      {...(!disableErrorOverlay ? { defaultChecked: true } : {})}
                      onChange={() => setDisableErrorOverlay(false)}
                      data-testid={`TESTID_DISABLE_REACT_ERROR_OVERLAY_OFF`}
                    />
                    Off
                  </div>
                </div>
                <br />
                <div>
                  <h4>Current URL</h4>
                  <a href={currentUrl}>{currentUrl}</a>
                </div>
                <br />
                <hr />
                <br />
                <div>
                  <span>Gatekeeper Network:&nbsp;</span>
                  <span data-testid={TESTID_CURRENT_GATEKEEPER_NETWORK}>{selectedGatekeeperNetwork?.id}</span>
                </div>
                <div>
                  <span>Chain RPC endpoint:</span>
                  <span>{chain === 'solana' ? solanaEndpoint : ''}</span>
                </div>
                <div>
                  <span>Compliance iframe URL:</span>
                  <a href={iframeUrl} target="_blank" data-testid={TESTID_CURRENT_IFRAME_URL}>
                    {iframeUrl ? `${iframeUrl.substring(0, 60)}...` : ''}
                  </a>
                </div>
                <div>
                  <span>Gatekeeper endpoint: </span>
                  <span>{gatekeeperEndpoint}</span>
                </div>
              </div>
              {selectedGatekeeperNetwork && stage && (
                <WrappedGatewayProvider
                  stage={stage}
                  options={{ autoShowModal: rcOptions.autoShowModal, logLevel }}
                  wrapperSelect={wrapperSelect}
                  gatekeeperNetworkAddress={selectedGatekeeperNetwork.id}
                  chain={chain}
                  setIFrameUrl={setIFrameUrl}
                  network={network as ExtendedCluster}
                  setNetwork={setNetwork}
                  gatekeeperSendsTransaction={!clientSends}
                  expiryMarginSeconds={expiryMarginSeconds}
                  connection={solanaConnection}
                  clientSendsDelay={clientSendsDelay}
                  clientSendsNoDelayOnRetry={clientSendsNoDelayOnRetry}
                  partnerAppId={partnerAppId}
                >
                  {chain === 'solana' && stage ? (
                    <SolanaGatewayStatusView
                      stage={stage}
                      setIFrameUrl={setIFrameUrl}
                      network={network as ExtendedCluster}
                      connection={solanaConnection}
                    />
                  ) : (
                    <EthereumGatewayStatusView />
                  )}
                </WrappedGatewayProvider>
              )}
            </header>
          </div>
        </WrappedWalletProvider>
      )}
    </>
  );
}

App.defaultProps = {
  stage: DEFAULT_STAGE,
};

export default App;
