import { Contract } from 'ethers';
import { State } from '@civic/common-gateway-react';
import { GATEWAY_PROTOCOL_CONTRACT_ADDRESS, NumericTokenState } from '../types';
import { GATEWAY_PROTOCOL_ABI } from './gatewayProtocolABI';
// Check for the existence of a particular pass on a chain
export const getToken = async (ethersProvider, evmGatekeeperNetwork, userAddress) => {
    const contract = new Contract(GATEWAY_PROTOCOL_CONTRACT_ADDRESS, GATEWAY_PROTOCOL_ABI, ethersProvider);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
    const tokenIDs = await contract.getTokenIdsByOwnerAndNetwork(userAddress, evmGatekeeperNetwork.slotId, false);
    const [pass] = await Promise.all(tokenIDs.map((tokenId) => contract.getToken(tokenId)));
    if (!pass) {
        return undefined;
    }
    return {
        identifier: tokenIDs[0].toString(),
        expiryTime: Number(pass.expiration),
        state: State[NumericTokenState[Number(pass.state)]],
        owner: pass.owner,
        gatekeeperNetworkAddress: evmGatekeeperNetwork.address,
        issuingGatekeeper: 'civic',
    };
};
