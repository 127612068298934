export const GKN_SERVICE_ENDPOINTS = {
    local: 'http://localhost:3001/local',
    test: 'https://dev.api.civic.com/gkn-dev',
    dev: 'https://dev.api.civic.com/gkn-dev',
    preprod: `https://dev.api.civic.com/gkn-preprod`,
    prod: 'https://api.civic.com/gkn',
};
export const getGatekeeperNetworkData = async (stage, gknId) => {
    if (!GKN_SERVICE_ENDPOINTS[stage]) {
        throw new Error(`No Gatekeeper Network Service endpoint found for stage ${stage}`);
    }
    const results = await fetch(`${GKN_SERVICE_ENDPOINTS[stage]}/public/gatekeeperNetwork/${gknId}`);
    const gknData = await results.json();
    if ((gknData === null || gknData === void 0 ? void 0 : gknData.id) !== gknId) {
        throw new Error(`Could not fetch data from Gatekeeper Network Service for ${gknId}`);
    }
    return gknData;
};
