import * as bs58 from 'bs58';
import { PublicKey } from '@solana/web3.js';
import { verifyTransaction, DEFAULT_CONFIG as DEFAULT_PROOF_CONFIG, verify } from '@identity.com/prove-solana-wallet';
import { SignedProof, SignatureMethod, SolanaSignedProofOptions } from '../../types';
import { ProofVerificationStrategy } from '.';
import logger from '../logger';

export const defaultOptions = {
  ...DEFAULT_PROOF_CONFIG,
  recentBlockCheck: false,
};

/**
 * Returns whether the given keyToCheck was used to sign the given signedProof.
 * @param signedProof The proof to evaluate
 * @param keyToCheckHex The Solana key in hex format
 * @param expectedMessage The message we expect to appear in the signature. This is usually a nonce.
 * @returns Boolean, whether the given key was a signer of the proof.
 */
export const verifySolanaProof: ProofVerificationStrategy = async (
  signedProof: SignedProof,
  keyToCheckHex: string,
  expectedMessage?: string
): Promise<boolean> => {
  let solanaPublicKey: PublicKey;
  try {
    const keyBase58 = bs58.encode(Buffer.from(keyToCheckHex, 'hex'));
    solanaPublicKey = new PublicKey(keyBase58);
  } catch {
    logger.warn('Skipping Solana proof verification for non-Solana key', keyToCheckHex);
    return false;
  }

  switch (signedProof.signatureMethod) {
    case SignatureMethod.TRANSACTION:
      return verifySignedTransaction(signedProof, solanaPublicKey);
    case SignatureMethod.MESSAGE:
      return verifySignedMessage(signedProof, solanaPublicKey, expectedMessage);
    default:
      throw new Error('Unsupported SignatureMethod in Solana signedProof');
  }
};

const verifySignedMessage = async (
  signedProof: SignedProof,
  publicKey: PublicKey,
  message: string
): Promise<boolean> => {
  try {
    const { proof } = signedProof;
    // verify can throw if the verification fails, so we can't just return it as a Promise<boolean>
    // as the caller expects the failure case to return false and not throw.
    await verify(publicKey, proof, message);
    return true;
  } catch (error) {
    logger.info(`signedProof was not signed by DID publicKey: ${publicKey}`, { error: error.message });
    return false;
  }
};

const verifySignedTransaction = async (signedProof: SignedProof, publicKey: PublicKey): Promise<boolean> => {
  try {
    const options = signedProof.options as SolanaSignedProofOptions;
    logger.debug(`Verifying Solana signature with:`, { signedProof, options, publicKey });
    await verifyTransaction(
      Buffer.from(signedProof.proof, 'base64'),
      publicKey,
      Object.assign(defaultOptions, options)
    );
    logger.info(`signedProof was signed by DID publicKey: ${publicKey}`);
    return true;
  } catch (error) {
    logger.info('verifySignedTransaction error when checking if proof was signed by publicKey', {
      signedProof,
      publicKey: publicKey.toBase58(),
      error: error.message,
    });
    return false;
  }
};

export const solanaKeyMatchesWallet = (keyHex: string, walletAddress: string) => {
  try {
    const keyBase58 = bs58.encode(Buffer.from(keyHex, 'hex'));
    return keyBase58.toLowerCase() === walletAddress.toLowerCase();
  } catch {
    return false;
  }
};
