import { convertCasperPubkeyToDid } from '@civic/casper-did';
import { CasperWalletAdapter, Chain, SignatureMethod, Wallet } from '../types';
import { windowEventListener, EventListener } from './eventListener';
import { RemoteWallet } from './remote';
const TIMEOUT = 60 * 1000;

/**
 *
 * @param wallet the casper wallet adapter
 * @returns {Wallet} a wallet object that can be used to sign a proof
 */
export const LocalCasperWallet = (wallet: CasperWalletAdapter): Wallet => ({
  getPublicKey: () => Promise.resolve(wallet.publicKey.toHex()),
  getDid: () => Promise.resolve(convertCasperPubkeyToDid(wallet.publicKey.toHex())),
  signProof: async (message: string) => {
    const proof = await wallet.signMessage(message);
    return {
      proof: Buffer.from(proof).toString('base64'),
      chain: Chain.CASPER,
      signatureMethod: SignatureMethod.MESSAGE,
    };
  },
  signMessage: async (message: Uint8Array) => wallet.signMessage(Buffer.from(message).toString('base64')),
  chain: Chain.CASPER,
});

/**
 *
 * @param timeout timeout in milliseconds waiting for the remote wallet to receive a response
 * @param eventListener the event listener to use for the remote wallet
 * @returns {Wallet}
 */
export const RemoteCasperWallet = ({
  timeout = TIMEOUT,
  eventListener = windowEventListener(),
  instanceId,
  debug,
}: {
  timeout: number;
  eventListener?: EventListener;
  instanceId: string;
  debug?: boolean;
}): Wallet => {
  // TODO CPASS-696 Anything special we need to do for Remote wallets to make it work with Casper?
  const remoteWallet = RemoteWallet({
    chain: Chain.CASPER,
    eventListener,
    timeout,
    instanceId,
    debug,
  });

  return remoteWallet;
};
