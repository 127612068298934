import { CivicSignVerify, SignedProof } from '../types';
import DefaultResolver, { DIDResolver } from './resolver';
import { getKeysFromDid, getProofVerificationStrategy } from './verification';

export const CivicVerifyImplementation = (resolver?: DIDResolver): CivicSignVerify => {
  const verify = async (did: string, signedProof: SignedProof, message: string) => {
    // 1. Resolve the DID document:
    const multichainResolver = resolver ?? new DefaultResolver();
    const didDocument = await multichainResolver.resolve(did);

    // 2. Determine how to verify a proof of this type:
    const verificationStrategy = getProofVerificationStrategy(signedProof);

    // 3. Apply the strategy to all keys on the did, and fail if none of them returned true.
    const verifyWithStrategy = (key: string) => verificationStrategy(signedProof, key, message);
    const verificationPromises = getKeysFromDid(didDocument).map(verifyWithStrategy);
    const verificationResults = await Promise.all(verificationPromises);

    if (!verificationResults.some((result: boolean) => !!result)) {
      throw new Error(`Signed proof verification failed`);
    }
  };

  return {
    verify,
  };
};
