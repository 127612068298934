/* eslint-disable @typescript-eslint/no-unused-vars */
import { LocalEthereumWallet, RemoteEthereumWallet } from './lib/ethereum';
import { CivicProveImplementation } from './lib/prove';
import { RemoteSolanaWallet, LocalSolanaWallet } from './lib/solana';
import {
  CasperWalletAdapter,
  CivicSignProve,
  EthereumWalletAdapter,
  SolanaSignedProofOptions,
  SolanaWalletAdapter,
} from './types';
import { CivicVerifyImplementation } from './lib/verify';
import { LocalCasperWallet, RemoteCasperWallet } from './lib/casper';
import logger from './lib/logger';

export { RemoteSignWindowEventEmitter, remoteSignWindowEventEmitterImplementation } from './lib/remoteSign';

export * from './types';

export const CivicSignProveFactory = {
  /**
   *
   * @param walletAdapter solana wallet adapter
   * @returns {CivicSignProve} the wallet proof object
   */
  createWithSolanaWallet: (walletAdapter: SolanaWalletAdapter, options?: SolanaSignedProofOptions): CivicSignProve => {
    return CivicProveImplementation(LocalSolanaWallet(walletAdapter, options));
  },

  /**
   *
   * @param walletAdapter casper wallet adapter
   * @returns {CivicSignProve} the wallet proof object
   */
  createWithCasperWallet: (walletAdapter: CasperWalletAdapter): CivicSignProve => {
    return CivicProveImplementation(LocalCasperWallet(walletAdapter));
  },

  /**
   *
   * @param walletAdapter ethereum wallet adapter
   * @returns {CivicSignProve} the wallet proof object
   */
  createWithEthereumWallet: (walletAdapter: EthereumWalletAdapter): CivicSignProve => {
    return CivicProveImplementation(LocalEthereumWallet(walletAdapter));
  },

  /**
   *
   * @param timeout remote wallet timeout in milliseconds. If the param is not set, the default timeout is used (60 * 1000)
   * @returns {CivicSignProve} the wallet proof object
   */
  createWithSolanaInIframe: (
    instanceId: string,
    { timeout, debug }: { timeout?: number; debug?: boolean }
  ): CivicSignProve => {
    debug && logger.debug(`[CivicSignProveFactory] createWithSolanaInIframe`, { instanceId });
    return CivicProveImplementation(RemoteSolanaWallet({ timeout, instanceId, debug }));
  },

  /**
   *
   * @param timeout remote wallet timeout in milliseconds. If the param is not set, the default timeout is used (60 * 1000)
   * @returns {CivicSignProve} the wallet proof object
   */
  createWithEthereumInIframe: (
    instanceId: string,
    { timeout, debug }: { timeout?: number; debug?: boolean }
  ): CivicSignProve => {
    debug && logger.debug(`[CivicSignProveFactory] createWithEthereumInIframe`, { instanceId });
    return CivicProveImplementation(RemoteEthereumWallet({ timeout, instanceId, debug }));
  },

  createWithCasperInIframe: (
    instanceId: string,
    { timeout, debug }: { timeout?: number; debug?: boolean }
  ): CivicSignProve => {
    debug && logger.debug(`[CivicSignProveFactory] createWithCasperInIframe`, { instanceId });
    return CivicProveImplementation(RemoteCasperWallet({ timeout, instanceId, debug }));
  },
};

export * from './types';
export const verify = CivicVerifyImplementation().verify;
export const CivicSignVerify = CivicVerifyImplementation;
export { walletOwnsDID } from './lib/verification';
