import logger from './logger';

export const getLibraryVersion = () => {
  try {
    if (process.env.LIB_VERSION) return process.env.LIB_VERSION; // this is set during build
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const packageJson = require('../../package.json');
    if (packageJson) {
      logger.debug(`${packageJson.name} version ${packageJson.version}`);
      return packageJson.version;
    }
  } catch (error) {
    logger.error('Error retrieving version from ../package.json');
  }
};

export class Deferred<T> {
  public promise: Promise<T>;
  private _resolve: (value: T) => void;
  private _reject: (error: Error) => void;

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  resolve(value?: T) {
    this._resolve(value);
  }

  reject(error: Error) {
    this._reject(error);
  }
}
