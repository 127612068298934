import LogLocal from './local.logger';
import { LoggingInterface, LoggingProvider } from './provider.logger';

const localLogger: LoggingInterface = new LoggingProvider(new LogLocal());

const prefixDebug =
  (prefix: string, globalPrefix: string) =>
  (message: string, obj: unknown = null) =>
    localLogger.debug(`[${globalPrefix}][${prefix}] ${message}`, obj);
const prefixError =
  (prefix: string, globalPrefix: string) =>
  (message: string, obj: unknown = null) =>
    localLogger.error(`[${globalPrefix}][${prefix}] ${message}`, obj);
const prefixWarn =
  (prefix: string, globalPrefix: string) =>
  (message: string, obj: unknown = null) =>
    localLogger.warn(`[${globalPrefix}][${prefix}] ${message}`, obj);
const prefixInfo =
  (prefix: string, globalPrefix: string) =>
  (message: string, obj: unknown = null) =>
    localLogger.info(`[${globalPrefix}][${prefix}] ${message}`, obj);

export const prefixLogger = (prefix: string, globalPrefix = 'CivicSign'): LoggingInterface => {
  return {
    debug: prefixDebug(prefix, globalPrefix),
    error: prefixError(prefix, globalPrefix),
    warn: prefixWarn(prefix, globalPrefix),
    info: prefixInfo(prefix, globalPrefix),
  };
};
export default localLogger;
